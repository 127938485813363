import { useMemo } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';

const useSearchParams = () => {
    const navigate = useNavigate();
    const {
        current: { searchStr, pathname },
    } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(searchStr);
        const updateParam = (name: string, value: string) => {
            if (!value) {
                params.delete(name);
            } else {
                params.set(name, value);
            }

            navigate({ to: `${pathname}?${params.toString()}` });
        };

        return {
            params: Object.fromEntries(params.entries()),
            updateParam,
        };
    }, [searchStr, navigate, pathname]);
};

export default useSearchParams;
