/* eslint max-lines: ["error", 270] */
import { DateTime } from 'luxon';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button } from '@mui/material';
import { useState } from 'react';
import { cleaningListQuery } from '../queries/cleaningQueries';
import AbstractButton from '../../base-ui/components/button/AbstractButton';
import formatMoney from '../../core/formatMoney';
import usePayCleaning from '../hooks/usePayCleaning';
import CleaningForm from './CleaningForm';

const moneyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    maximumFractionDigits: 0,
});

const currentYear = new Date().getFullYear().toString();
const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
const currentMonthPrefix = `${currentYear}-${currentMonth}`;

const Cleaning = () => {
    const [showCleaningForm, setShowCleaningForm] = useState(false);
    const {
        data: { cleaningList = [] } = {
            cleaningList: [],
        },
    } = useQuery<{
        cleaningList: {
            id: number;
            time: string;
            note: string;
            value: number;
            paid: boolean;
        }[];
    }>(cleaningListQuery);
    const [payCleaningToDate] = useMutation(
        gql`
            mutation PayCleaningToDate {
                payCleaningToDate
            }
        `,
        {
            refetchQueries: [{ query: cleaningListQuery }],
        }
    );
    const payCleaning = usePayCleaning();

    const costToDate = cleaningList
        .filter(
            item =>
                DateTime.fromFormat(item.time, 'yyyy-MM-dd hh:mm').diffNow(
                    'days'
                ).days < 0
        )
        .reduce((a, { value }) => a + value, 0);

    const totalThisMonth = cleaningList
        .filter(item => item.time.startsWith(currentMonthPrefix))
        .reduce((a, b) => a + b.value, 0);

    const suggestedTime = `${DateTime.local().toSQLDate()} 12:00`;
    return (
        <div>
            <Button
                variant="contained"
                onClick={() => {
                    setShowCleaningForm(true);
                }}
            >
                Add
            </Button>
            {showCleaningForm && (
                <CleaningForm
                    suggestedTime={suggestedTime}
                    onClose={() => {
                        setShowCleaningForm(false);
                    }}
                />
            )}
            <table>
                <tbody>
                    {cleaningList.map(cleaning => (
                        <tr key={cleaning.id}>
                            <td>
                                <AbstractButton
                                    onPress={() => {
                                        /*
                                        updateParams({
                                            page: 'cleaning',
                                            id: cleaning.id,
                                        });
                                        */
                                    }}
                                >
                                    {cleaning.time}
                                </AbstractButton>
                            </td>
                            <td>{moneyFormatter.format(cleaning.value)}</td>
                            <td>{cleaning.note}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total this month</td>
                        <td>
                            {formatMoney(totalThisMonth, 'EUR')}{' '}
                            <Button
                                variant="contained"
                                onClick={() => {
                                    payCleaning({
                                        month: currentMonthPrefix,
                                    }).catch(error => {
                                        throw error;
                                    });
                                }}
                            >
                                Pay this month
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td>Total to date</td>
                        <td>
                            {moneyFormatter.format(costToDate)}{' '}
                            {costToDate > 0 && (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        payCleaningToDate().catch(error => {
                                            throw error;
                                        });
                                    }}
                                >
                                    Pay total to date
                                </Button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>
                            {moneyFormatter.format(
                                cleaningList.reduce(
                                    (a, { value }) => a + value,
                                    0
                                )
                            )}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Cleaning;
