import React, { ReactNode } from 'react';
import cn from 'clsx';

import './AbstractButton.scss';

interface Props {
    children: ReactNode;
    className?: string;
    title?: string;
    onPress?(): void;
}

const Button = ({ children, onPress, className, title }: Props) => (
    <button
        className={cn('AbstractButton', className)}
        type="button"
        tabIndex={0}
        onClick={onPress}
        onKeyPress={onPress}
        title={title}
    >
        {children}
    </button>
);

export default Button;
