import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { State } from '../store';
import { paramsActions } from '../store/actions/params';
import Calendar from './Calendar';

const ConnectedCalendar = connect(
    ({ calendar, params: { time } }: State) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentTime: time
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              DateTime.fromFormat(time, 'yyyy-MM-dd').toISO()!
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              DateTime.local().toISO()!,
        ...calendar,
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                updateParams: paramsActions.updateParams,
            },
            dispatch
        )
)(Calendar);

export default ConnectedCalendar;
