import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useMatch } from '@tanstack/react-location';
import { memo } from 'react';
import { Box, Button, Skeleton, TextField, useMediaQuery } from '@mui/material';
import assert from '../../core/util/assert';
import CheckinChecklist from '../../shared-ui/component/CheckinChecklist';
import CheckoutChecklist from '../../shared-ui/component/CheckoutChecklist';
import { calculateInvoiceItems } from '../calculation/invoiceBreakdown';
import CountryForm from './CountryForm';
import BookingSelectField from './BookingSelectField';
import BookingBooleanField from './BookingBooleanField';
import BookingTextField from './BookingTextField';
import GuestList from './GuestList';

import MarkAsCompleteButton from './MarkAsCompleteButton';

interface Guest {
    id: number;
    firstName: string;
    lastName: string;
    sex: string;
    nationality: string;
    country: string;
    birthDate: string;
    birthPlace: string;
    postcode: string;
    address: string;
    idDocumentType?: string;
    idDocumentId?: string;
    valid: boolean;
    price: number;
    touristTax: number;
    nationalityNameHU: string;
    countryNameHU: string;
}

const bookingViewQuery = gql`
    query booking($id: Int!) {
        booking(id: $id) {
            id
            reference
            status
            invoiceId
            detailsRecorded
            actionRequired
            urgentActionRequired
            bookingShouldBeComplete
            countryCode
            country {
                nameHU
                nationalityHU
            }
            checkin
            checkinTime
            checkout
            checkoutTime
            revenue
            cleaningFee
            guestName
            lockboxCode
            guestCheckinUrl
            contactPhone
            guests {
                id
                firstName
                lastName
                sex
                nationality
                nationalityNameHU
                country
                countryNameHU
                birthDate
                birthPlace
                postcode
                address
                idDocumentType
                idDocumentId
                valid
                price
                touristTax
            }
        }
    }
`;

const BookingView = memo(() => {
    const shouldSplitScreen = useMediaQuery('(min-width:820px)');
    const match = useMatch();

    const bookingId = +match.params.id;

    const client = useApolloClient();
    const [addGuest, { loading: addGuestLoading }] = useMutation(
        gql`
            mutation addGuest($bookingId: Int!) {
                addGuest(bookingId: $bookingId)
            }
        `,
        {
            variables: {
                bookingId,
            },
        }
    );
    const [requestUpdate, { loading: requestUpdateLoading }] = useMutation(
        gql`
            mutation requestBookingUpdateFromUpstream($bookingId: Int!) {
                requestBookingUpdateFromUpstream(bookingId: $bookingId)
            }
        `,
        {
            variables: {
                bookingId,
            },
        }
    );

    const [deleteInstructions] = useMutation(
        gql`
            mutation deleteInstructions($bookingId: Int!) {
                deleteInstructions(bookingId: $bookingId)
            }
        `,
        {
            refetchQueries: [
                {
                    query: bookingViewQuery,
                    variables: { bookingId },
                },
            ],
        }
    );

    const { data, loading, error } = useQuery<{
        booking: {
            id: number;
            reference: string;
            status: string;
            invoiceId?: string;
            detailsRecorded: boolean;
            countryCode: string;
            country: {
                nameHU: string;
                nationalityHU: string;
            };
            checkin: string;
            checkinTime: string;
            checkout: string;
            checkoutTime: string;
            revenue: number;
            cleaningFee: number;
            guestName: string;
            lockboxCode?: string;
            guestCheckinUrl: string;
            actionRequired: boolean;
            urgentActionRequired: boolean;
            bookingShouldBeComplete: boolean;
            contactPhone: string;
            guests: Guest[];
        };
    }>(bookingViewQuery, {
        variables: { id: bookingId },
        skip: !match.params.id,
    });

    if (loading || addGuestLoading || requestUpdateLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: shouldSplitScreen ? 'row' : 'column-reverse',
                    gap: 3,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                    <Box>
                        <Skeleton width={216} />
                    </Box>
                </Box>
            </Box>
        );
    }

    if (error) {
        return <>{error.toString()}</>;
    }

    assert(data);

    const { booking } = data;

    const invoiceItems = calculateInvoiceItems(booking);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: shouldSplitScreen ? 'row' : 'column-reverse',
                gap: 3,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box>
                    <TextField label="Reference" value={booking.reference} />
                </Box>
                <BookingSelectField
                    label="Status"
                    id={booking.id}
                    field="status"
                    value={booking.status}
                    options={[
                        'booked',
                        'accommodationVerified',
                        'keysSecured',
                        'hasReview',
                        'complete',
                        'canceled',
                    ]}
                />
                <Box>
                    <Button
                        onClick={() => {
                            window.open(
                                `https://www.airbnb.co.uk/hosting/reservations/details/${booking.reference}`
                            );
                        }}
                    >
                        Open booking
                    </Button>
                    <Button
                        onClick={() => {
                            window.open(`${booking.guestCheckinUrl}`);
                        }}
                    >
                        Open checkin
                    </Button>
                </Box>
                <CountryForm
                    contactPhone={booking.contactPhone}
                    bookingId={booking.id}
                    value={booking.countryCode}
                />
                <BookingTextField
                    label="Lockbox code"
                    id={booking.id}
                    field="lockboxCode"
                    value={booking.lockboxCode}
                />
                <BookingTextField
                    label="Checkin date"
                    id={booking.id}
                    field="checkin"
                    value={booking.checkin}
                />
                <BookingTextField
                    label="Checkin time"
                    id={booking.id}
                    field="checkinTime"
                    value={booking.checkinTime}
                />
                <BookingTextField
                    label="Checkout date"
                    id={booking.id}
                    field="checkout"
                    value={booking.checkout}
                />
                <BookingTextField
                    label="Checkout time"
                    id={booking.id}
                    field="checkoutTime"
                    value={booking.checkoutTime}
                />
                <Box>
                    <Button
                        onClick={() => {
                            requestUpdate()
                                .then(async () => {
                                    await client.clearStore();
                                    await client.reFetchObservableQueries();
                                })
                                .catch(addError => {
                                    throw addError;
                                });
                        }}
                    >
                        Request update from upstream
                    </Button>
                </Box>
                <BookingTextField
                    label="Revenue"
                    id={booking.id}
                    field="revenue"
                    value={booking.revenue.toString()}
                    type="numeric"
                />
                <BookingTextField
                    label="Invoice id"
                    id={booking.id}
                    field="invoiceId"
                    value={booking.invoiceId}
                />
                <Box>
                    <h3>Invoice breakdown</h3>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Accommodation Price"
                            value={invoiceItems.accommodationPrice}
                        />
                        <TextField
                            label="Cleaning fee"
                            value={invoiceItems.cleaningFee}
                        />
                        <TextField
                            label="Tourist tax"
                            value={invoiceItems.touristTax}
                        />
                        <TextField
                            label="Admin fee"
                            value={invoiceItems.adminFee}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            addGuest()
                                .then(async () => {
                                    await client.clearStore();
                                    await client.reFetchObservableQueries();
                                })
                                .catch(addError => {
                                    throw addError;
                                });
                        }}
                    >
                        Add guest
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            deleteInstructions({
                                variables: {
                                    bookingId: booking.id,
                                },
                            })
                                .then(async () => {
                                    await client.clearStore();
                                    await client.reFetchObservableQueries();
                                })
                                .catch(addError => {
                                    throw addError;
                                });
                        }}
                    >
                        Delete instructions
                    </Button>
                </Box>

                <BookingBooleanField
                    label={'Details recorded'}
                    field={'detailsRecorded'}
                    value={booking.detailsRecorded}
                    id={booking.id}
                />

                <GuestList guests={booking.guests} bookingId={booking.id} />
            </Box>
            <Box>
                <CheckinChecklist bookingId={booking.id} />
                <CheckoutChecklist bookingId={booking.id} />
                {booking.bookingShouldBeComplete && (
                    <MarkAsCompleteButton booking={booking} />
                )}
            </Box>
        </Box>
    );
});

export default BookingView;
