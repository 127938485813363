import { Fragment, useState } from 'react';
import { useQuery, gql } from '@apollo/client';

import './TaxBreakdown.scss';
import ErrorBox from '../../core/components/ErrorBox';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';

const numberFormatter = new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
});

const TaxBreakdown = () => {
    const {
        loading,
        error,
        data: { touristTaxByMonth: monthRows } = {
            touristTaxByMonth: [],
        },
    } = useQuery<{
        touristTaxByMonth: {
            month: string;
            bookings: {
                checkin: string;
                invoiceId: string;
                revenue: number;
                cleaningFee: number;
                guests: {
                    price: number;
                    touristTax: number;
                }[];
            }[];
            amount: number;
        }[];
    }>(gql`
        {
            touristTaxByMonth {
                month
                bookings {
                    checkin
                    invoiceId
                    revenue
                    cleaningFee
                    guests {
                        price
                        touristTax
                    }
                }
                amount
            }
        }
    `);
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(
        undefined
    );

    if (!selectedMonth) {
        if (monthRows.length) {
            setSelectedMonth(monthRows[0].month);
        }
        return null;
    }

    if (error) {
        return <ErrorBox error={error} />;
    }

    if (loading) {
        return <SpinnerIcon />;
    }

    return (
        <div className="TaxBreakdown">
            <select
                onChange={event => {
                    setSelectedMonth(event.target.value);
                }}
            >
                {monthRows.map(({ month }) => (
                    <option key={month} value={month}>
                        {month}
                    </option>
                ))}
            </select>
            {monthRows
                .filter(({ month }) => month === selectedMonth)
                .map(row => (
                    <table key={row.month} className="TaxBreakdownTable">
                        <tbody>
                            {row.bookings.map(booking => (
                                <Fragment key={booking.invoiceId}>
                                    <tr>
                                        <th>{booking.invoiceId}</th>
                                        <th>{booking.checkin}</th>
                                    </tr>
                                    {booking.guests.map((guest, index) => (
                                        <tr key={index}>
                                            <td>{guest.price}</td>
                                            <td>{guest.touristTax}</td>
                                        </tr>
                                    ))}
                                </Fragment>
                            ))}
                            <tr>
                                <th>{row.month.replace('-', '/')}</th>
                                <th className="TaxBreakdownTableAmount">
                                    {numberFormatter.format(
                                        Math.ceil(row.amount)
                                    )}
                                </th>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <th className="TaxBreakdownTableAmount">
                                    {numberFormatter.format(
                                        Math.ceil(
                                            row.bookings.reduce(
                                                (a, b) =>
                                                    a +
                                                    b.revenue -
                                                    b.cleaningFee,
                                                0
                                            )
                                        )
                                    )}
                                </th>
                            </tr>
                        </tbody>
                    </table>
                ))}
        </div>
    );
};

export default TaxBreakdown;
