import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const SpinnerIcon = (props: Partial<FontAwesomeIconProps>) => (
    <FontAwesomeIcon spin icon={faSpinner} size="3x" {...props} />
);

export default SpinnerIcon;
