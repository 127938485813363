import { useQuery } from '@apollo/client';
import { useState } from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@mui/material';
import { Booking } from '../../domain/booking';
import BookingChecklistItemState from '../../booking/components/BookingChecklistItemState';
import { checklistQuery } from '../../main/queries';
import assert from '../../core/util/assert';
import { InvoiceViewDialog } from '../../booking/components/operation/InvoiceView';
import ReviewDialog from '../../booking/components/ReviewDialog';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';

interface Props {
    bookingId: number;
}

const CheckoutChecklist = ({ bookingId }: Props) => {
    const { data, error, loading } = useQuery<{
        booking: Booking;
    }>(checklistQuery, { variables: { id: bookingId } });
    const [createInvoiceModalOpen, setCreateInvoiceModalOpen] = useState(false);
    const [reviewDialogOpen, setReviewDialogOpen] = useState(false);

    if (loading) return <SpinnerIcon />;

    if (error) return <p>Error :(</p>;

    assert(data);

    const { booking } = data;

    return (
        <Box>
            <Typography variant="h5" component="h2">
                Checkout checklist
            </Typography>
            <FormGroup>
                <BookingChecklistItemState
                    label={'Accommodation verified'}
                    booking={booking}
                    statusField="accommodationVerified"
                />
                <BookingChecklistItemState
                    label={'Keys secured'}
                    booking={booking}
                    statusField="keysSecured"
                />
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!booking.hasReview}
                                color="success"
                                onClick={() => {
                                    if (!booking.hasReview) {
                                        setReviewDialogOpen(true);
                                    }
                                }}
                            />
                        }
                        label={'Review'}
                    />
                    {reviewDialogOpen && (
                        <ReviewDialog
                            booking={booking}
                            onClose={() => {
                                setReviewDialogOpen(false);
                            }}
                        />
                    )}
                </Box>
                {!booking.invoiceId && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!booking.invoiceId}
                                color="success"
                                onClick={() => {
                                    if (!booking.invoiceId) {
                                        setCreateInvoiceModalOpen(true);
                                    }
                                }}
                            />
                        }
                        label={'Invoice'}
                    />
                )}
                {!!booking.invoiceId && (
                    <>
                        <BookingChecklistItemState
                            label={'Invoice'}
                            booking={booking}
                            statusField="invoiceId"
                        />
                    </>
                )}
                {createInvoiceModalOpen && (
                    <InvoiceViewDialog
                        bookingId={bookingId}
                        onFocusLost={() => {
                            setCreateInvoiceModalOpen(false);
                        }}
                    />
                )}
            </FormGroup>
        </Box>
    );
};

export default CheckoutChecklist;
