import { useQuery, gql } from '@apollo/client';

import './Balance.scss';
/*
import {
    Table,
    TableBody,
    TableSummary,
    TableRow,
    TableSummaryValueCell,
    TableSummaryLabelCell,
    TableValueCell,
} from '../../base-ui/components/Table';
*/
/*
const moneyFormatter = new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
});
*/

const Balance = () => {
    const year = 2022;
    const { data, error, loading } = useQuery<{
        balance: {
            vat: number;
            touristTax: number;
            tourismTax: number;
        };
    }>(
        gql`
            query balance($year: Int) {
                balance(year: $year) {
                    vat
                    touristTax
                    tourismTax
                }
            }
        `,
        {
            variables: {
                year,
            },
        }
    );
    if (loading) return <p>Loading...</p>;
    if (error || !data) return <p>Error :(</p>;

    return (
        <div className="balance">
            {/*
            <Table>
                <TableBody>
                    <tr key={year}>
                        <th colSpan={2}>{year}</th>
                    </tr>
                    
                    {months.map(month => (
                        <>
                            <tr key={month.month}>
                                <th colSpan={2}>{month.month}</th>
                            </tr>
                            {month.items.map(item => (
                                <tr key={item.id}>
                                    <td>{item.description}</td>
                                    <TableValueCell>
                                        {moneyFormatter.format(item.value)}
                                    </TableValueCell>
                                </tr>
                            ))}
                            <tr key={`${month.month}-revenue`}>
                                <TableSummaryLabelCell>
                                    Revenue
                                </TableSummaryLabelCell>
                                <TableSummaryValueCell>
                                    {moneyFormatter.format(
                                        month.items
                                            .filter(({ value }) => value >= 0)
                                            .reduce((a, b) => a + b.value, 0)
                                    )}
                                </TableSummaryValueCell>
                            </tr>
                            <tr key={`${month.month}-expenses`}>
                                <TableSummaryLabelCell>
                                    Expenses
                                </TableSummaryLabelCell>
                                <TableSummaryValueCell>
                                    {moneyFormatter.format(
                                        month.items
                                            .filter(({ value }) => value < 0)
                                            .reduce((a, b) => a + b.value, 0)
                                    )}
                                </TableSummaryValueCell>
                            </tr>
                            <tr key={`${month.month}-summary`}>
                                <TableSummaryLabelCell>
                                    Net
                                </TableSummaryLabelCell>
                                <TableSummaryValueCell>
                                    {moneyFormatter.format(
                                        month.items.reduce(
                                            (a, b) => a + b.value,
                                            0
                                        )
                                    )}
                                </TableSummaryValueCell>
                            </tr>
                        </>
                    ))}
                </TableBody>
                <TableSummary>
                    <TableRow key={`${year}-total`}>
                        
                        <TableSummaryLabelCell>
                            {`${year} total`}
                        </TableSummaryLabelCell>
                        <TableSummaryValueCell>
                            {moneyFormatter.format(
                                months.reduce(
                                    (a, b) =>
                                        a +
                                        b.items
                                            .filter(item => item.value >= 0)
                                            .reduce(
                                                (total, month) =>
                                                    total + month.value,
                                                0
                                            ),
                                    0
                                )
                            )}
                        </TableSummaryValueCell>
                        
                    </TableRow>
                    
                </TableSummary>
            </Table>
            */}
        </div>
    );
};

export default Balance;
