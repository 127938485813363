/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint max-lines: 0 */
import { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import cn from 'clsx';
import { DateTime } from 'luxon';
import DropZone from '../../base-ui/components/DropZone';
import DropBox from '../../main/component/common/DropBox';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import { homeQuery } from '../../main/queries';
import './BankingPage.scss';

const moneyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    maximumFractionDigits: 0,
});

const importBankingTransactionsMutation = gql`
    mutation importBankingTransactions($file: Upload!) {
        importBankingTransactions(file: $file)
    }
`;

const bankingQuery = gql`
    {
        bookings(statuses: ["booked"]) {
            id
            guestName
            revenue
            checkout
        }
        bankingTransactions {
            id
            date
            amount
            oppositeAccountName
            reference
            type
            bookings {
                id
                guestName
                revenue
            }
        }
    }
`;

const BankingPage = () => {
    const [importing, setImporting] = useState(false);
    const [importBankingTransactions] = useMutation(
        importBankingTransactionsMutation,
        {
            refetchQueries: [
                {
                    query: bankingQuery,
                },
            ],
        }
    );
    const [isFileDraggedIn, setFileDraggedIn] = useState(false);
    const {
        data: { bankingTransactions, bookings } = {
            bankingTransactions: [],
            bookings: [],
        },
        loading,
    } = useQuery<{
        bankingTransactions: {
            id: number;
            date: string;
            amount: number;
            oppositeAccountName: string;
            reference: string;
            type: string;
            bookings: {
                id: number;
                guestName: string;
                revenue: number;
            }[];
        }[];
        bookings: {
            id: number;
            guestName: string;
            revenue: number;
            checkout: string;
        }[];
    }>(bankingQuery);
    const [
        associateBankingTransactionWithBooking,
        { loading: associateBankingTransactionWithBookingLoading },
    ] = useMutation(
        gql`
            mutation AssociateBankingTransactionWithBookingMutation(
                $transactionId: ID!
                $bookingId: Int
            ) {
                associateBankingTransactionWithBooking(
                    transactionId: $transactionId
                    bookingId: $bookingId
                )
            }
        `,
        {
            refetchQueries: [
                {
                    query: bankingQuery,
                },
                {
                    query: homeQuery,
                },
            ],
        }
    );
    const [disassociateBankingTransactionWithBooking] = useMutation(
        gql`
            mutation DisassociateBankingTransactionWithBookingMutation(
                $transactionId: ID!
                $bookingId: Int
            ) {
                disassociateBankingTransactionWithBooking(
                    transactionId: $transactionId
                    bookingId: $bookingId
                )
            }
        `,
        {
            refetchQueries: [
                {
                    query: bankingQuery,
                },
                {
                    query: homeQuery,
                },
            ],
        }
    );

    if (loading || importing || associateBankingTransactionWithBookingLoading) {
        return <SpinnerIcon />;
    }

    // TODO: optimize this
    const usedBookingIds = bankingTransactions
        .flatMap(item => item.bookings.map(({ id }) => id))
        .filter(v => !!v);

    const today = DateTime.local().toSQLDate() || '';

    const availableBookings = bookings
        .filter(({ id }) => !usedBookingIds.includes(id))
        .filter(item => item.checkout < today)
        .sort((a, b) => a.checkout.localeCompare(b.checkout));

    return (
        <div className="bankingPage">
            <DropZone
                onDragEnter={() => {
                    setFileDraggedIn(true);
                }}
                onDragLeave={() => {
                    setFileDraggedIn(false);
                }}
                onDropFiles={files => {
                    setImporting(true);
                    importBankingTransactions({
                        variables: { file: files.item(0) },
                    })
                        .catch(error => {
                            throw error;
                        })
                        .finally(() => {
                            setImporting(false);
                        });
                }}
            >
                <DropBox highlight={isFileDraggedIn} />
            </DropZone>
            <table>
                <tbody>
                    {bankingTransactions.map(transaction => (
                        <tr
                            key={transaction.id}
                            className={cn({
                                bankingTransactionOfInterest:
                                    transaction.reference
                                        .toLowerCase()
                                        .includes('airbnb') &&
                                    !transaction.bookings.length,
                                bankingTransactionExplained:
                                    !!transaction.bookings.length,
                            })}
                        >
                            <td>{transaction.date}</td>
                            <td style={{ textAlign: 'right' }}>
                                {moneyFormatter.format(transaction.amount)}
                            </td>
                            <td>{transaction.oppositeAccountName}</td>
                            <td>{transaction.reference}</td>
                            <td>{transaction.type}</td>
                            <td>
                                {transaction.bookings.map(booking => (
                                    <div key={booking.id}>
                                        {`${
                                            booking.guestName
                                        } ${moneyFormatter.format(
                                            booking.revenue
                                        )}`}
                                        <button
                                            onClick={() => {
                                                disassociateBankingTransactionWithBooking(
                                                    {
                                                        variables: {
                                                            transactionId:
                                                                transaction.id,
                                                            bookingId:
                                                                booking.id,
                                                        },
                                                    }
                                                ).catch(error => {
                                                    throw error;
                                                });
                                            }}
                                            type="button"
                                        >
                                            &#x2718;
                                        </button>
                                    </div>
                                ))}
                                <select
                                    value=""
                                    onChange={event => {
                                        associateBankingTransactionWithBooking({
                                            variables: {
                                                transactionId: transaction.id,
                                                bookingId: +event.target.value,
                                            },
                                        }).catch(error => {
                                            throw error;
                                        });
                                    }}
                                >
                                    <option />
                                    {availableBookings.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {`${
                                                item.guestName
                                            } ${moneyFormatter.format(
                                                item.revenue
                                            )}${
                                                item.revenue ===
                                                transaction.amount
                                                    ? '*'
                                                    : ''
                                            }`}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BankingPage;
