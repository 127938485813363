import { useState, createRef, RefObject } from 'react';
import cn from 'clsx';
import HBox from '../../main/component/common/HBox';
import Button from './button/AbstractButton';
import CopyIcon from './icon/CopyIcon';

const ValueInput = ({
    value,
    inputRef,
    highlighted,
    type,
}: {
    value: string;
    type?: 'textarea' | 'hidden';
    inputRef: RefObject<HTMLInputElement> | RefObject<HTMLTextAreaElement>;
    highlighted?: boolean;
}) => {
    if (type === 'textarea') {
        return (
            <textarea
                style={{ flexGrow: 1 }}
                value={value}
                className={cn({
                    TextCopyInputHighlighted: highlighted,
                })}
                readOnly
                ref={inputRef as RefObject<HTMLTextAreaElement>}
            />
        );
    }

    if (type === 'hidden') {
        return (
            <input
                value={value}
                className={cn({
                    TextCopyInputHighlighted: highlighted,
                })}
                style={{ width: 1, background: 'transparent' }}
                type="text"
                readOnly
                ref={inputRef as RefObject<HTMLInputElement>}
            />
        );
    }

    return (
        <input
            value={value}
            className={cn({
                TextCopyInputHighlighted: highlighted,
            })}
            type="text"
            readOnly
            ref={inputRef as RefObject<HTMLInputElement>}
        />
    );
};

const TextCopyInput = ({
    value,
    type,
}: {
    value: string;
    type?: 'textarea' | 'hidden';
}) => {
    const [highlighted, setHighlighted] = useState(true);
    const inputRef = createRef<HTMLInputElement | HTMLTextAreaElement>() as
        | RefObject<HTMLInputElement>
        | RefObject<HTMLTextAreaElement>;

    return (
        <HBox>
            <ValueInput
                value={value}
                highlighted={highlighted}
                type={type}
                inputRef={inputRef}
            />
            <Button
                onPress={() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    inputRef.current!.select();
                    document.execCommand('copy');
                    setHighlighted(false);
                }}
            >
                <CopyIcon />
            </Button>
        </HBox>
    );
};

export default TextCopyInput;
