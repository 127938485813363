import { createAction, ActionsUnion } from '.';

export type Page =
    | 'tokenForm'
    | 'home'
    | 'bookingForm'
    | 'bookingManage'
    | 'bookingList'
    | 'guestbook'
    | 'taxBreakdown'
    | 'cleaning'
    | 'calendar'
    | 'settings'
    | 'invoices'
    | 'expenses'
    | 'messageTemplates'
    | 'messageTemplateForm'
    | 'balance'
    | 'stats'
    | 'run'
    | 'banking';

interface GenericParams {
    page: Page;
}

interface BookingManageParams extends GenericParams {
    page: 'bookingManage';
    id: string;
}

interface CleaningParams extends GenericParams {
    page: 'cleaning';
    id: number;
}

interface ExpensesParams extends GenericParams {
    page: 'expenses';
    id: number;
}

interface CalendarParams extends GenericParams {
    time?: string;
}

interface CommandParams extends GenericParams {
    page: 'run';
    command: 'selectTheme';
    param: string;
}

export type Params =
    | ExpensesParams
    | GenericParams
    | BookingManageParams
    | CalendarParams
    | CleaningParams
    | CommandParams;

export const paramsActions = {
    updateParams: (params: Params) => createAction('params/update', params),
};

export type ParamsAction = ActionsUnion<typeof paramsActions>;
