/* eslint @typescript-eslint/ban-types: 0 */
import { useMutation, gql } from '@apollo/client';

export const cleaningListQuery = gql`
    query CleaningList {
        cleaningList(paid: false) {
            id
            time
            note
            value
            paid
        }
    }
`;

export const cleaningQuery = gql`
    query CleaningList($id: ID) {
        cleaning(id: $id) {
            id
            time
            note
            value
            paid
        }
    }
`;

export const createCleaningQuery = gql`
    mutation CreateCleaning(
        $time: String!
        $note: String!
        $value: Int!
        $paid: Boolean!
    ) {
        createCleaning(time: $time, note: $note, value: $value, paid: $paid) {
            id
        }
    }
`;

export const updateCleaningQuery = gql`
    mutation UpdateCleaning(
        $id: ID!
        $time: String!
        $note: String!
        $value: Int!
        $paid: Boolean!
    ) {
        updateCleaning(
            id: $id
            time: $time
            note: $note
            value: $value
            paid: $paid
        ) {
            id
        }
    }
`;

export const useRemoveCleaningMutation = () =>
    useMutation<
        {},
        {
            id: string;
        }
    >(
        gql`
            mutation removeCleaning($id: ID!) {
                removeCleaning(id: $id)
            }
        `,
        {
            refetchQueries: [{ query: cleaningListQuery }],
        }
    );

/*
export interface MessageTemplatesQueryResult {
    messageTemplates: Array<{
        name: string;
        value: string;
    }>;
}

export const messageTemplateQuery = gql`
    query messageTemplate($name: String!) {
        messageTemplate(name: $name) {
            name,
            value,
        }
    }
`;
*/
