import Modal from '@istvan.xyz/ui/Modal';
import { Button } from '@mui/material';
import TextCopyInput from '../../base-ui/components/TextCopyInput';
import { Booking } from '../../domain/booking';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import useAlterBookingState from '../hooks/useAlterBookingState';

/* eslint-disable no-template-curly-in-string */

const ReviewDialog = ({
    booking,
    onClose,
}: {
    booking: Booking;
    onClose(): void;
}) => {
    const [alterBookingState, { loading: alterBookingStateLoading }] =
        useAlterBookingState(booking.id);

    if (alterBookingStateLoading) {
        return <SpinnerIcon />;
    }

    return (
        <Modal
            footer={
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            alterBookingState({
                                variables: {
                                    id: booking.id,
                                    fields: { hasReview: true },
                                },
                            })
                                .catch(error => {
                                    throw error;
                                })
                                .finally(() => {
                                    onClose();
                                });
                        }}
                    >
                        Mark as sent
                    </Button>
                </div>
            }
        >
            <div className="bookingMessageGenerator">
                <TextCopyInput
                    type="textarea"
                    value={"${firstName}'s group were good guests. I can recommend them to my fellow hosts without any hesitation.".replace(
                        '${firstName}',
                        booking.guests[0].firstName
                    )}
                />
                <Button
                    variant="contained"
                    onClick={() => {
                        window.open(
                            `https://www.airbnb.co.uk/hosting/reservations/details/${booking.reference}`
                        );
                    }}
                >
                    Open booking
                </Button>
            </div>
        </Modal>
    );
};

export default ReviewDialog;
