import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';

const useClearQueryCache = () => {
    const client = useApolloClient();
    return useMemo(
        () => async () => {
            await client.clearStore();
            await client.reFetchObservableQueries();
        },
        [client]
    );
};

export default useClearQueryCache;
