import React from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { Params } from '../store/actions/params';
import ProblemIcon from './icon/ProblemIcon';
import styles from './CalendarEvent.module.scss';

export interface Event {
    name: string;
    actionRequired?: boolean;
    urgentActionRequired?: boolean;
    color: string;
    start: string;
    end: string;
    open?(): void;
}

const CalendarEvent = ({
    event,
    reference,
}: {
    event: Event;
    reference: DateTime;
    updateParams(params: Params): void;
}) => {
    const isStart =
        DateTime.fromISO(event.start).startOf('day').diff(reference, 'day')
            .days === 0;
    return (
        <div
            className={clsx(styles.calendarEvent, {
                [styles.calendarEventStart]: isStart,
            })}
        >
            <div
                className={styles.calendarEventSummary}
                style={{ backgroundColor: event.color }}
                role="button"
                tabIndex={0}
                onClick={event.open}
                onKeyPress={event.open}
            >
                {isStart ? (
                    <>
                        {event.actionRequired ? (
                            <>
                                <ProblemIcon color="orange" />{' '}
                            </>
                        ) : null}
                        {event.urgentActionRequired ? (
                            <>
                                <ProblemIcon />{' '}
                            </>
                        ) : null}
                        {event.name}
                    </>
                ) : (
                    <span className="CalendarEventPlaceholder">&nbsp;</span>
                )}
            </div>
        </div>
    );
};

export default CalendarEvent;
