/* eslint @typescript-eslint/ban-types: 0 */
import { MutationFunctionOptions, gql } from '@apollo/client';

export interface MessageTemplatesQueryResult {
    messageTemplates: {
        name: string;
        value: string;
    }[];
}

export const messageTemplatesQuery = gql`
    query MessageTemplates {
        messageTemplates {
            name
            value
        }
    }
`;

export const messageTemplateQuery = gql`
    query messageTemplate($name: String!) {
        messageTemplate(name: $name) {
            name
            value
        }
    }
`;

export type SaveMessageTemplateAction = (
    options: MutationFunctionOptions<
        {},
        {
            fields: {
                name: string;
                value: number;
            };
        }
    >
) => void;

export const saveMessageTemplateQuery = gql`
    mutation saveMessageTemplate($name: String!, $message: String!) {
        saveMessageTemplate(name: $name, message: $message) {
            name
            value
        }
    }
`;

export const removeMessageTemplateQuery = gql`
    mutation removeMessageTemplate($name: String!) {
        removeMessageTemplate(name: $name)
    }
`;
