/* eslint max-lines: ["error", 400] */
import { useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';
import HBox from '../../../main/component/common/HBox';
import Column from '../../../main/component/common/Column';
import { toDateTime } from '../../../domain/date';
import { calculateInvoiceItems } from '../../calculation/invoiceBreakdown';
import TextCopyInput from '../../../base-ui/components/TextCopyInput';
import useAlterBookingState from '../../hooks/useAlterBookingState';
import SpinnerIcon from '../../../main/component/icon/SpinnerIcon';
import useQuery from '../../../core/hooks/useQuery';
import './InvoiceView.scss';

// eslint-disable-next-line import/no-unresolved, import/extensions
import createInvoiceCode from '../../../invoice/automation/createInvoice.js?raw';

/* eslint no-mixed-operators: 0 */
export const InvoiceViewDialog = ({
    bookingId,
    onFocusLost,
}: {
    bookingId: number;
    onFocusLost: () => void;
}) => {
    const client = useApolloClient();
    const [saving, setSaving] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [alterBookingState] = useAlterBookingState(bookingId);
    const { loading, data, error } = useQuery<{
        booking: {
            id: number;
            reference: string;
            guestName: string;
            revenue: number;
            cleaningFee: number;
            checkin: string;
            checkout: string;
            guests: {
                id: number;
                firstName: string;
                lastName: string;
                birthDate: string;
                countryNameEN: string;
                postcode: string;
                address: string;
                price: number;
                touristTax: number;
            }[];
        };
        bankingTransactions: {
            date: string;
            amount: number;
        }[];
    }>(
        gql`
            query InvoiceViewDialogQuery($id: Int!) {
                booking(id: $id) {
                    id
                    reference
                    guestName
                    revenue
                    cleaningFee
                    checkin
                    checkout
                    guests {
                        id
                        firstName
                        lastName
                        countryNameEN
                        birthDate
                        postcode
                        address
                        price
                        touristTax
                    }
                }
                bankingTransactions(bookingId: $id) {
                    date
                    amount
                }
            }
        `,
        { variables: { id: bookingId } }
    );

    if (error) {
        return <Dialog open>{error.toString()}</Dialog>;
    }

    if (loading) {
        return <SpinnerIcon />;
    }

    if (saving) {
        return <SpinnerIcon />;
    }

    const { booking, bankingTransactions } = data;
    const { reference, revenue, checkin, checkout, guests } = booking;
    const [{ firstName, lastName, countryNameEN, postcode, address }] = guests;

    const invoiceItems = calculateInvoiceItems(booking);

    /* spellchecker: disable */
    return (
        <Dialog open onClose={onFocusLost}>
            <DialogContent>
                <div className="InvoiceViewDialog">
                    <div className="InvoiceViewDialogInfo">
                        <div>SZÁMLAKIBOCSÁTÓ</div>
                        <div>PARTNER</div>
                        <div>
                            <div>Teljesítés</div>
                            <div>
                                {bankingTransactions &&
                                    bankingTransactions.map(
                                        ({ date, amount }) => (
                                            <div key={date}>
                                                {date} {amount}
                                            </div>
                                        )
                                    )}
                            </div>
                        </div>
                        <div>
                            <div>Cégnév/név</div>
                            <TextCopyInput value={`${firstName} ${lastName}`} />
                        </div>
                        <div>
                            <div>Rendelésszám</div>
                            <TextCopyInput value={reference} />
                        </div>
                        <div>
                            <div>Számlázási cím</div>
                            <div>Ország</div>
                            <TextCopyInput value={countryNameEN} />
                            <HBox>
                                <Column expanded>
                                    <div>Irányítószám</div>
                                    <TextCopyInput value={postcode} />
                                </Column>
                                <Column expanded>
                                    <div>Település</div>
                                    <input type="text" value="" readOnly />
                                </Column>
                            </HBox>
                            <div>Utca, házszám</div>
                            <TextCopyInput value={address} />
                        </div>
                    </div>
                    <div>Tételek</div>
                    <table className="InvoiceViewDialogTable">
                        <thead>
                            <tr>
                                <th>Tétel neve</th>
                                <th>Nettó egységár</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>lakás kiadás</td>
                                <td>
                                    <TextCopyInput value={revenue.toString()} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextCopyInput
                                        value={`${guests.length} vendégnek ${
                                            checkout
                                                ? toDateTime(checkout).diff(
                                                      toDateTime(checkin),
                                                      'days'
                                                  ).days
                                                : 0
                                        } éjszakára `}
                                    />
                                </td>
                                <td />
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td />
                                <td>{revenue}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <TextCopyInput
                        type="textarea"
                        value={`${createInvoiceCode}run(${JSON.stringify({
                            reference: booking.reference,
                            guestName: `${firstName} ${lastName}`,
                            paymentDate: bankingTransactions.length
                                ? bankingTransactions[0].date
                                : new Date().toISOString().substring(0, 10),
                            invoiceItems,
                            countryName: countryNameEN,
                            postcode,
                            address,
                            accommodationItemDescription: `${
                                guests.length
                            } vendégnek ${
                                checkout
                                    ? toDateTime(checkout).diff(
                                          toDateTime(checkin),
                                          'days'
                                      ).days
                                    : 0
                            } éjszakára, takarítással`,
                        })});`}
                    />
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                window.open(
                                    'https://www.szamlazz.hu/szamla/?page=szamlaszerkeszto',
                                    '_blank'
                                );
                            }}
                        >
                            Open site
                        </Button>
                    </div>
                    {!bankingTransactions.length && (
                        <span>Banking transaction not found!</span>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <TextField
                    value={invoiceId}
                    onChange={event => {
                        setInvoiceId(event.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    disabled={!invoiceId || !booking.id}
                    onClick={() => {
                        setSaving(true);
                        alterBookingState({
                            variables: {
                                id: booking.id,
                                fields: {
                                    invoiceId,
                                },
                            },
                        })
                            .then(async () => client.resetStore())
                            .catch(saveError => {
                                throw saveError;
                            })
                            .finally(() => {
                                setSaving(false);
                                onFocusLost();
                            });
                    }}
                >
                    Set invoice
                </Button>
            </DialogActions>
        </Dialog>
    );
    /* spellchecker: enable */
};

const InvoiceView = ({ bookingId }: { bookingId: number }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    setOpen(true);
                }}
            >
                Invoice view
            </Button>
            {open && (
                <InvoiceViewDialog
                    bookingId={bookingId}
                    onFocusLost={() => {
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default InvoiceView;
