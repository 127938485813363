import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

const updateMutation = gql`
    mutation updateGuest($updates: GuestUpdates!) {
        updateGuest(updates: $updates)
    }
`;

const useUpdateGuestField = <T>({
    id,
    field,
    initialValue,
    defaultValue,
    isNumeric,
}: {
    id: string | number;
    field: string;
    initialValue: T;
    defaultValue: T;
    isNumeric?: boolean;
}) => {
    const client = useApolloClient();
    const [currentValue, setValue] = useState<T>(initialValue || defaultValue);
    const [performUpdate] = useMutation(updateMutation);

    const update = useCallback(() => {
        performUpdate({
            variables: {
                updates: {
                    id,
                    [field]: isNumeric ? +currentValue : currentValue,
                },
            },
        })
            .then(async () => {
                await client.clearStore();
                await client.reFetchObservableQueries();
            })
            .catch(error => {
                throw error;
            });
    }, [currentValue, id, client, performUpdate, field]);

    return {
        currentValue,
        setValue,
        update,
    };
};

export default useUpdateGuestField;
