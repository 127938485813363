import { useMutation, gql, useApolloClient } from '@apollo/client';
import { Button } from '@mui/material';
import { homeQuery } from '../../main/queries';

const MarkAsCompleteButton = ({ booking }: { booking: { id: number } }) => {
    const client = useApolloClient();

    const [updateBookingStatus] = useMutation(
        gql`
            mutation updateBookingStatus($bookingId: Int!, $status: String!) {
                updateBookingStatus(bookingId: $bookingId, status: $status)
            }
        `,
        {
            variables: {
                bookingId: booking.id,
                status: 'complete',
            },
            refetchQueries: [
                {
                    query: homeQuery,
                },
            ],
        }
    );

    return (
        <Button
            variant="contained"
            onClick={() => {
                updateBookingStatus()
                    .then(async () => {
                        await client.clearStore();
                        await client.reFetchObservableQueries();
                    })
                    .catch(error => {
                        throw error;
                    });
            }}
        >
            Mark as complete
        </Button>
    );
};

export default MarkAsCompleteButton;
