import { useMutation } from '@apollo/client';
import {
    alterBookingStateQuery,
    checklistQuery,
    homeQuery,
} from '../../main/queries';

const useAlterBookingState = (bookingId: number) => {
    return useMutation(alterBookingStateQuery, {
        refetchQueries: [
            { query: checklistQuery, variables: { id: bookingId } },
            { query: homeQuery },
        ],
    });
};

export default useAlterBookingState;
