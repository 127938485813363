const formatters = {
    EUR: new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
    }),
    HUF: new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'HUF',
        maximumFractionDigits: 0,
    }),
    GBP: new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
    }),
};

const formatMoney = (value: number, currency: 'EUR' | 'HUF' | 'GBP') =>
    formatters[currency].format(value);

export default formatMoney;
