import React, { ReactNode } from 'react';
import cn from 'clsx';

import './Column.scss';

interface Props {
    children: ReactNode;
    expanded?: boolean;
}

const Column = ({ children, expanded }: Props) => (
    <div className={cn('Column', { expanded })}>{children}</div>
);

export default Column;
