import { memo } from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import useUpdateBookingField from '../hooks/useUpdateBookingField';

const BookingSelectField = memo(
    ({
        id,
        value,
        label,
        field,
        options,
    }: {
        label: string;
        field: string;
        id: number;
        value?: string;
        options: string[];
    }) => {
        const { currentValue, setValue, update } = useUpdateBookingField({
            id,
            field,
            initialValue: value,
            defaultValue: '',
        });

        return (
            <Box>
                <FormControl>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        label={label}
                        value={currentValue}
                        onChange={event => {
                            setValue(event.target.value);
                        }}
                    >
                        {options.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {(value || '') !== currentValue && (
                    <>
                        {' '}
                        <Button onClick={update}>Save</Button>
                    </>
                )}
            </Box>
        );
    }
);

export default BookingSelectField;
