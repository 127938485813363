import { useState } from 'react';

import './InvoiceList.scss';
import { gql, useMutation } from '@apollo/client';
import DropZone from '../../base-ui/components/DropZone';
import DropBox from './common/DropBox';

/*
const moneyFormatter = new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
});

interface Props {
    accommodationPriceEditingId?: number;
    accommodationPriceEditingValue: string;
    isFileDraggedIn: boolean;
    isProcessing: boolean;
    invoices: Invoice[];
    upload(invoiceFileExport: File): void;
    dragEnter(): void;
    dragLeave(): void;
    editAccommodationPrice(id: number): void;
    updateAccommodationPrice(price: string): void;
    doneEditingAccommodationPrice(): void;
}

const groupInvoices = (invoices: Invoice[]) => {
    if (!invoices.length) {
        return [];
    }
    let group: { month: string; invoices: Invoice[] } = {
        month: invoices[0].performanceDate.substr(0, 7),
        invoices: [],
    };
    const groups: { month: string; invoices: Invoice[] }[] = [group];

    for (const invoice of invoices) {
        if (group.month !== invoice.performanceDate.substr(0, 7)) {
            group = {
                month: invoice.performanceDate.substr(0, 7),
                invoices: [],
            };
            groups.push(group);
        }
        group.invoices.push(invoice);
    }

    return groups;
};
*/

const invoiceListUploadMutation = gql`
    mutation uploadInvoiceList($file: Upload!) {
        uploadInvoiceList(file: $file)
    }
`;

const InvoiceList = () => {
    const [uploadInvoiceList] = useMutation(invoiceListUploadMutation);
    const [isFileDraggedIn, setIsFileDraggedIn] = useState(false);
    const dragEnter = () => {
        setIsFileDraggedIn(true);
    };
    const dragLeave = () => {
        setIsFileDraggedIn(false);
    };

    const upload = (invoiceFileExport: File) => {
        uploadInvoiceList({
            variables: {
                file: invoiceFileExport,
            },
        }).catch(error => {
            throw error;
        });
        /*
        (async () => {
            const body = new FormData();
            body.append('file', invoiceFileExport);
            await (
                await fetch(`${baseUrl}/api/invoiceImport`, {
                    method: 'post',
                    headers: {
                        Authorization: `bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                    body,
                })
            ).json();
            // store.dispatch(invoicesActions.reload());
            // store.dispatch(invoicesActions.uploadDone());
            setIsFileDraggedIn(false);
        })().catch(error => {
            throw error;
        });
        */
    };

    return (
        /*{
    invoices,
    isFileDraggedIn,
    isProcessing,
    upload,
    dragEnter,
    dragLeave,
    accommodationPriceEditingId,
    accommodationPriceEditingValue,
    editAccommodationPrice,
    updateAccommodationPrice,
    doneEditingAccommodationPrice,
}: Props*/ <div className="InvoiceList">
            {/*
        {isProcessing && (
            <div className="SyncSpinner">
                <SpinnerIcon />
            </div>
        )}
        <table>
            <tbody>
                {groupInvoices(invoices).map(group => (
                    <Fragment key={group.month}>
                        {group.invoices.map(invoice => (
                            <tr key={invoice.invoiceId}>
                                <td>{invoice.invoiceId}</td>
                                <td>{invoice.buyer}</td>
                                <td className="InvoiceListPrice">
                                    {moneyFormatter.format(invoice.grossPrice)}
                                </td>
                                <td
                                    className="InvoiceListPrice"
                                    onDoubleClick={() => {
                                        editAccommodationPrice(invoice.id);
                                    }}
                                >
                                    {accommodationPriceEditingId ===
                                    invoice.id ? (
                                        <input
                                            type="text"
                                            className="InvoiceListPrice"
                                            value={
                                                accommodationPriceEditingValue
                                            }
                                            style={{
                                                width: `${Math.floor(
                                                    accommodationPriceEditingValue.length *
                                                        0.75
                                                )}rem`,
                                            }}
                                            autoFocus
                                            onChange={event => {
                                                updateAccommodationPrice(
                                                    event.target.value
                                                );
                                            }}
                                            onBlur={
                                                doneEditingAccommodationPrice
                                            }
                                        />
                                    ) : (
                                        moneyFormatter.format(
                                            invoice.accommodationPrice
                                        )
                                    )}
                                </td>
                                <td>{invoice.performanceDate}</td>
                            </tr>
                        ))}
                        <tr key={group.month}>
                            <td />
                            <td />
                            <td className="InvoiceListPrice">
                                {moneyFormatter.format(
                                    group.invoices.reduce(
                                        (a, b) => a + b.grossPrice,
                                        0
                                    )
                                )}
                            </td>
                            <td className="InvoiceListPrice">
                                {moneyFormatter.format(
                                    group.invoices.reduce(
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        (a, b) => a + b.accommodationPrice,
                                        0
                                    )
                                )}
                            </td>
                            <td>{group.month}</td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        </table>
        */}
            <DropZone
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDropFiles={files => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    upload(files.item(0)!);
                }}
            >
                <DropBox highlight={isFileDraggedIn} />
            </DropZone>
        </div>
    );
};

export default InvoiceList;
