import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';
import useClearQueryCache from '../../core/hooks/useClearQueryCache';

const payCleaningMutation = gql`
    mutation payCleaning($month: String) {
        payCleaning(month: $month)
    }
`;

const usePayCleaning = () => {
    const clearQueryCache = useClearQueryCache();
    const [mutation] = useMutation(payCleaningMutation);

    return useMemo(
        () =>
            async ({ month }: { month: string }) => {
                await mutation({ variables: { month } });
                await clearQueryCache();
            },
        [mutation, clearQueryCache]
    );
};

export default usePayCleaning;
