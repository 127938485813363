import { DateTime } from 'luxon';
import { toDateTime } from '../../domain/date';

/* eslint no-mixed-operators: ["error", {"allowSamePrecedence": true}] */
export const calculateTouristTax = (price: number) =>
    Math.round((price / 1.04) * 0.04);

const calculateRoundedAccommodationPrice = (booking: {
    cleaningFee: number;
    revenue: number;
    checkin: string;
    checkout: string;
    guests: {
        birthDate: string;
        price: number;
        touristTax: number;
    }[];
}) => {
    const total = booking.revenue - booking.cleaningFee;
    const initialTouristTax = calculateTouristTax(total);
    const dayCount = toDateTime(booking.checkout).diff(
        toDateTime(booking.checkin),
        'days'
    ).days;

    const accommodationPrice =
        Math.floor((total - initialTouristTax) / dayCount) * dayCount;
    return accommodationPrice;
};

const calculateFullTouristTax = (booking: {
    cleaningFee: number;
    revenue: number;
    checkin: string;
    checkout: string;
    guests: {
        birthDate: string;
        price: number;
        touristTax: number;
    }[];
}) => {
    const dayCount = toDateTime(booking.checkout).diff(
        toDateTime(booking.checkin),
        'days'
    ).days;

    const accommodationPrice = calculateRoundedAccommodationPrice(booking);
    return Math.round((accommodationPrice * 0.04) / dayCount) * dayCount;
};

export const calculateTouristTaxesForGuests = (booking: {
    cleaningFee: number;
    revenue: number;
    checkin: string;
    checkout: string;
    guests: {
        birthDate: string;
        price: number;
        touristTax: number;
    }[];
}) => {
    const ageCutoff = DateTime.local().minus({ years: 18 });
    const fullTouristTax = calculateFullTouristTax(booking);

    return booking.guests.map(guest =>
        DateTime.fromISO(guest.birthDate).diff(ageCutoff, 'day').days < 0
            ? fullTouristTax / booking.guests.length
            : 0
    );
};

export const calculateInvoiceItems = (booking: {
    cleaningFee: number;
    revenue: number;
    checkin: string;
    checkout: string;
    guests: {
        birthDate: string;
        price: number;
        touristTax: number;
    }[];
}) => {
    const touristTax = Math.round(booking.revenue * 0.04);

    return {
        accommodationPrice: booking.revenue - touristTax,
        cleaningFee: booking.cleaningFee,
        touristTax,
        adminFee: 0,
    };
};
