import { useState } from 'react';

const TokenForm = () => {
    const [token, setToken] = useState('');
    return (
        <form
            onSubmit={e => {
                e.preventDefault();

                localStorage.setItem('token', token);
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }}
            className="TokenForm"
        >
            <div>
                <label>
                    Token
                    <input
                        type="text"
                        value={token}
                        onChange={e => {
                            setToken(e.target.value);
                        }}
                    />
                </label>
            </div>
            <button type="submit">Authenticate</button>
        </form>
    );
};

export default TokenForm;
