import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronLeft);

const BackIcon = (props: Partial<Props>) => (
    <FontAwesomeIcon icon="chevron-left" size="1x" {...props} />
);

export default BackIcon;
