/* eslint max-lines: ["error", 600], max-len: 0 */
import { useState, createRef, RefObject } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import cn from 'clsx';
import CopyIcon from '../../../base-ui/components/icon/CopyIcon';
import VBox from '../../../main/component/common/layout/VBox';
import useAlterBookingState from '../../hooks/useAlterBookingState';
import SpinnerIcon from '../../../main/component/icon/SpinnerIcon';

const TextCopyInput = ({
    value,
    type,
}: {
    value: string;
    type?: 'textarea';
}) => {
    const [highlighted, setHighlighted] = useState(true);
    const inputRef = createRef<HTMLInputElement | HTMLTextAreaElement>();
    return (
        <VBox>
            {type === 'textarea' ? (
                <textarea
                    value={value}
                    className={cn({
                        TextCopyInputHighlighted: highlighted,
                    })}
                    readOnly
                    ref={inputRef as RefObject<HTMLTextAreaElement>}
                />
            ) : (
                <input
                    value={value}
                    className={cn({
                        TextCopyInputHighlighted: highlighted,
                    })}
                    type="text"
                    readOnly
                    ref={inputRef as RefObject<HTMLInputElement>}
                />
            )}
            <div>
                <Button
                    variant="contained"
                    onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        inputRef.current!.select();
                        document.execCommand('copy');
                        setHighlighted(false);
                    }}
                >
                    <CopyIcon />
                </Button>
            </div>
        </VBox>
    );
};

const GuestBookView = ({
    bookingId,
    onClose,
}: {
    bookingId: number;
    onClose: () => void;
}) => {
    const { data, error } = useQuery<{
        booking: {
            country: {
                nameHU: string;
                nationalityHU: string;
            };
            checkin: string;
            checkout: string;
            revenue: number;
            cleaningFee: number;
            guestName: string;
        };
    }>(
        gql`
            query GuestBookViewDialogQuery($id: Int!) {
                booking(id: $id) {
                    id
                    country {
                        nameHU
                        nationalityHU
                    }
                    checkin
                    checkout
                    revenue
                    cleaningFee
                    guestName
                }
            }
        `,
        { variables: { id: bookingId } }
    );

    const [alterBookingState, { loading: alterBookingStateLoading }] =
        useAlterBookingState(bookingId);

    if (alterBookingStateLoading) {
        return <SpinnerIcon />;
    }

    if (error) {
        return <Dialog open>{error.toString()}</Dialog>;
    }

    if (!data) {
        return <SpinnerIcon />;
    }

    const {
        booking: { country, checkin, checkout, revenue, guestName },
    } = data;

    return (
        <Dialog open>
            <DialogContent>
                <div>
                    Code
                    <TextCopyInput
                        type="textarea"
                        /* spell-checker: disable */
                        value={`
                    (async () => {
                        // Library
                        const monthMap = {
                            'Január': 1,
                            'Február': 2,
                            'Március': 3,
                            'Április': 4,
                            'Május': 5,
                            'Június': 6,
                            'Július': 7,
                            'Augusztus': 8,
                            'Szeptember': 9,
                            'Október': 10,
                            'November': 11,
                            'December': 12,
                        };
                        const sleep = duration => new Promise(resolve => setTimeout(resolve, duration));
                        const triggerTextEditChange = (selector, value) => {
                            $(selector).val(value).focus();
                            $(selector)[0].dispatchEvent(new InputEvent('input', { data: value }));
                            $(selector)[0].dispatchEvent(new UIEvent('change'));
                            $(selector)[0].dispatchEvent(new UIEvent('blur'));
                        };
                        const setDatePickerValue = async (year, month, day) => {
                            console.group('setDatePickerValue');
                            console.log('setDatePickerValue', year, month, day);
                            await sleep(100);
                            if ($("select.ui-datepicker-year").length) {
                                triggerTextEditChange(".ui-datepicker-year", year);
                                triggerTextEditChange(".ui-datepicker-month", month - 1);
                                await sleep(100);
                            } else {
                                console.log($(".ui-datepicker-year"));
                                console.log($(".ui-datepicker-month"));
                                let currentYear = +$(".ui-datepicker-year").text();
                                console.log('currentYear', currentYear);
                                while (currentYear !== year) {
                                    if (currentYear < year) {
                                        $('.ui-datepicker-next')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                                    } else {
                                        // $('.ui-datepicker-prev').click();
                                        $('.ui-datepicker-prev')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                                    }
                                    currentYear = +$(".ui-datepicker-year").text();
                                    console.log('currentYear', currentYear);
                                }

                                let currentMonth = monthMap[$(".ui-datepicker-month").text()];
                                console.log('currentMonth', currentMonth);
                                await sleep(100);

                                while (currentMonth !== month) {
                                    if (currentMonth < month) {
                                        console.log('Next', $('.ui-datepicker-next')[0]);
                                        // $('.ui-datepicker-next').click();
                                        $('.ui-datepicker-next')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                                    } else {
                                        console.log('Prev', $('.ui-datepicker-next')[0]);
                                        // $('.ui-datepicker-prev').click();
                                        $('.ui-datepicker-prev')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                                    }
                                    await sleep(100);
                                    currentMonth = monthMap[$(".ui-datepicker-month").text()];
                                    console.log('currentMonth', currentMonth);
                                    await sleep(100);
                                }
                            }
                            await sleep(100);
                            const dayButton = $(".ui-datepicker-calendar a")
                                .toArray().find(e => e.innerText.trim() === day.toString());
                            if (!dayButton) {
                                console.error('dayButton not found');
                                console.log($(".ui-datepicker-calendar a")
                                .toArray().map(e => e.innerText.trim()));
                                debugger;
                                throw new Error(\`Cannot find button: \${day}\`);
                                /* await (new Promise(resolve => {
                                    const handler = () => {
                                        $(document).off('click', '.ui-datepicker-calendar a', handler);
                                        resolve();
                                    };
                                    $(document).on('click', '.ui-datepicker-calendar a', handler);
                                }));
                                */
                            } else {
                                console.log('Click', dayButton);
                                dayButton.dispatchEvent(new MouseEvent('click'));
                            }
                            await sleep(100);
                            console.log('Success');
                            console.groupEnd('setDatePickerValue');
                        };
                        const openDatePicker = (selector) => {
                            $(selector + " .ui-datepicker-trigger").click();
                        };
                        const selectDate = async (selector, year, month, day) => {
                            openDatePicker(selector);
                            return setDatePickerValue(year, month, day);
                        };
                        const selectDropdownItem = async (selector, value) => {
                            $(selector + " label").click();
                            await sleep(100);
                            $($(".ui-dropdown-item")[value + 1]).click()
                        };
                        const selectDropdownValue = async (selector, value) => {
                            $(selector + " label").click();
                            await sleep(100);
                            $($(".ui-dropdown-item").toArray().filter(item => item.innerText === value)).click()
                        };
                        const openDropdownItem = async selector => {
                            $(selector + " label").click();
                            return new Promise(resolve => {
                                const handler = () => {
                                    $(document).off('click', '.ui-dropdown-item', handler);
                                    resolve();
                                };
                                $(document).on('click', '.ui-dropdown-item', handler);
                            });
                        };

                        const click = elementOrSelector => {
                            let element;
                            if (typeof elementOrSelector === 'string') {
                                element = document.querySelector(elementOrSelector);
                            } else {
                                element = elementOrSelector;
                            }
                            element.dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, view: window }));
                        };

                        // Instructions

                        location.hash = '/naptarnezet';
                        await sleep(500);
                        $("#foglalasokAtTekintese button").click();
                        await sleep(500);
                        $("#ujFoglalasGomb button").click();
                        await sleep(500);

                        openDatePicker("#erkezesUtazasDatuma_0");
                        await setDatePickerValue(${checkin.slice(
                            0,
                            4
                        )}, ${checkin
                            .slice(5, 7)
                            .replace(/^0?([^0].*)$/g, '$1')}, ${checkin
                            .slice(8)
                            .replace(/^0?([^0].*)$/g, '$1')});
                        await sleep(100);
                        await setDatePickerValue(${checkout.slice(
                            0,
                            4
                        )}, ${checkout
                            .slice(5, 7)
                            .replace(/^0?([^0].*)$/g, '$1')}, ${checkout
                            .slice(8)
                            .replace(/^0?([^0].*)$/g, '$1')});

                        await sleep(100);
                        $('app-naptar-gombok .ui-button-secondary').click();
                        await sleep(100);

                        await selectDropdownItem("#lakoegysegAzonosito_0", 0);

                        triggerTextEditChange("#teljesAr_0 input", "${revenue}");


                        await sleep(1000);
                        click('.green-button button');

                        triggerTextEditChange("#megrendelo-nev-input", "${guestName}");

                        await selectDropdownItem("#foglalasMod", 3);

                        await selectDropdownValue("#megrendeloAllampolgarsag", "${
                            country.nationalityHU
                        }");

                        await sleep(100);
                        click('.green-button button');

                        await sleep(100);
                        click('.green-button button');

                        click(document.querySelector('.ui-dropdown-label[aria-label="Foglalás csatornája"]').parentElement);

                        click(Array.from(document.querySelectorAll('.ui-dropdown-item')).find(item => item.innerText === 'Közvetítő-online'))

                        click('#megrendeloAllampolgarsag .ui-dropdown');

                        click(Array.from(document.querySelectorAll('.ui-dropdown-item')).find(item => item.innerText === '${
                            country.nationalityHU
                        }'))

                        console.log('Finishing');
                        await sleep(100);
                        click('.green-button button');
                    })().catch(error => {
                        throw error;
                    });
                    
                    `
                            .split('\n')
                            .map(line => line.slice(5 * 4))
                            .join('\n')
                            .trim()}
                        /* spell-checker: enable */
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        window.open('https://vendegem.hu');
                    }}
                >
                    Open NTAK
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        alterBookingState({
                            variables: {
                                id: bookingId,
                                fields: {
                                    importedToGovernmentPortal: true,
                                },
                            },
                        })
                            .catch(saveError => {
                                throw saveError;
                            })
                            .finally(() => {
                                onClose();
                            });
                    }}
                >
                    Mark as imported
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GuestBookView;
