import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import clsx from 'clsx';
import { Booking } from '../../domain/booking';
import { Params } from '../store/actions/params';
import { calendarQuery } from '../../booking/queries/calendar';
import CleaningForm from '../../cleaning/component/CleaningForm';
import { cleaningFormActions } from '../../cleaning/store/actions/cleaningForm';
import useActions from '../store/util/useActions';
import TransparentButton from '../../base-ui/components/button/TransparentButton';
import Button from '../../ui/components/Button';
import VBox, { ExpandedRow } from './common/layout/VBox';
import HBox from './common/HBox';
import BackIcon from './icon/BackIcon';
import ForwardIcon from './icon/ForwardIcon';
import PlusIcon from './icon/PlusIcon';
import styles from './Calendar.module.scss';
import CalendarEvent, { Event } from './CalendarEvent';
/* eslint no-loop-func: 0, max-lines: ["error", 250] */

/* eslint-disable @typescript-eslint/no-loop-func */

const colorScale = scaleOrdinal<number, string>(schemeCategory10);

interface Props {
    currentTime: string;
    bookings: Booking[];
    updateParams(params: Params): void;
}

const Calendar = ({ currentTime, updateParams }: Props) => {
    const [cleaningFormDay, setCleaningFormDay] = useState<
        string | undefined
    >();
    const { setField } = useActions(cleaningFormActions);
    const {
        data: { bookings = [], cleaningList = [] } = {
            bookings: [],
            cleaningList: [],
        },
    } = useQuery<{
        bookings: Booking[];
        cleaningList: {
            id: number;
            time: string;
            note: string;
            value: number;
        }[];
    }>(calendarQuery, {
        variables: {
            statuses: ['complete', 'booked'],
        },
    });
    const current = DateTime.fromISO(currentTime);
    const today = DateTime.local();
    const currentTimeStartOfDay = today.startOf('day');
    const currentTimeEndOfDay = today.endOf('day');
    const viewStartDate = current.startOf('month').startOf('week');
    const viewEndDate = current.endOf('month').endOf('week');

    const events: Event[] = bookings
        .filter(
            booking =>
                (viewStartDate <= DateTime.fromISO(booking.checkin) &&
                    DateTime.fromISO(booking.checkin) <= viewEndDate) ||
                (viewStartDate <= DateTime.fromISO(booking.checkout) &&
                    DateTime.fromISO(booking.checkout) <= viewEndDate)
        )
        .map((item, index) => ({
            name: `${item.guestName}, ${item.guests.length} guests`,
            actionRequired: item.actionRequired,
            urgentActionRequired: item.urgentActionRequired,
            color: colorScale(index),
            start: item.checkin,
            end: item.checkout,
            open: () => {
                updateParams({
                    page: 'bookingManage',
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    id: `${item.id!}`,
                });
            },
        }));

    for (const cleaning of cleaningList.filter(({ time }) => {
        const cleaningTime = DateTime.fromFormat(time, 'yyyy-MM-dd HH:mm');
        return viewStartDate <= cleaningTime && cleaningTime <= viewEndDate;
    })) {
        const cleaningTime = DateTime.fromFormat(
            cleaning.time,
            'yyyy-MM-dd HH:mm'
        );
        events.push({
            name: `Cleaning at ${cleaningTime.toFormat('HH:mm')}`,
            color: 'orange',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            start: cleaningTime.toISO()!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            end: cleaningTime.plus({ hours: 3 }).toISO()!,
            open: () => {
                updateParams({
                    page: 'cleaning',
                    id: cleaning.id,
                });
            },
        });
    }

    let iterator = viewStartDate;
    let currentWeekList: {
        time: DateTime;
        events: Event[];
        isCurrentDay: boolean;
    }[] = [];
    const weeks: {
        time: DateTime;
        events: Event[];
        isCurrentDay: boolean;
    }[][] = [currentWeekList];
    let currentWeekNumber = viewStartDate.weekNumber;

    while (iterator <= viewEndDate) {
        if (currentWeekNumber !== iterator.weekNumber) {
            currentWeekNumber = iterator.weekNumber;
            currentWeekList = [];
            weeks.push(currentWeekList);
        }

        currentWeekList.push({
            time: iterator,
            isCurrentDay:
                currentTimeStartOfDay <= iterator &&
                iterator <= currentTimeEndOfDay,
            events: events.filter(
                event =>
                    DateTime.fromISO(event.start).startOf('day') <= iterator &&
                    iterator <= DateTime.fromISO(event.end).endOf('day')
            ),
        });

        iterator = iterator.plus({ days: 1 });
    }

    return (
        <VBox expanded>
            <HBox>
                <TransparentButton
                    onPress={() => {
                        updateParams({
                            page: 'calendar',
                            time: current
                                .minus({ month: 1 })
                                .startOf('month')
                                .toFormat('yyyy-MM-dd'),
                        });
                    }}
                >
                    <BackIcon />
                </TransparentButton>
                <h2
                    style={{
                        marginLeft: '0.5rem',
                        marginRight: '0.5rem',
                        marginBottom: '0.5rem',
                    }}
                >
                    {current.toFormat('MMMM')}
                </h2>
                <TransparentButton
                    onPress={() => {
                        updateParams({
                            page: 'calendar',
                            time: current
                                .plus({ month: 1 })
                                .startOf('month')
                                .toFormat('yyyy-MM-dd'),
                        });
                    }}
                >
                    <ForwardIcon />
                </TransparentButton>
            </HBox>
            {cleaningFormDay && <CleaningForm onClose={() => {}} />}
            <ExpandedRow className={styles.calendar}>
                {weeks.map(week =>
                    week.map(d => (
                        <div
                            className={clsx(styles.column, {
                                [styles.current]: d.isCurrentDay,
                            })}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            key={d.time.toISODate()!}
                        >
                            <div className={styles.timeLabel}>{d.time.day}</div>
                            {d.events.map((event, key) => (
                                <CalendarEvent
                                    key={key}
                                    event={event}
                                    reference={d.time}
                                    updateParams={updateParams}
                                />
                            ))}
                            <div className={styles.calendarDayAddEvent}>
                                <Button
                                    onPress={() => {
                                        setField({
                                            name: 'date',
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            value: d.time.toISODate()!,
                                        });
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        setCleaningFormDay(d.time.toISODate()!);
                                    }}
                                >
                                    <PlusIcon />
                                </Button>
                            </div>
                        </div>
                    ))
                )}
            </ExpandedRow>
        </VBox>
    );
};

export default Calendar;
