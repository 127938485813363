import { ReactNode } from 'react';
import styles from './Button.module.css';

interface Props {
    children: ReactNode;
    onPress?(): void;
    title?: string;
}

const GenericButton = ({ children, onPress, title }: Props) => (
    <button
        className={styles.button}
        type="button"
        tabIndex={0}
        onClick={onPress}
        onKeyPress={onPress}
        title={title}
    >
        {children}
    </button>
);

export default GenericButton;
