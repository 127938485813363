import { createAction, ActionsUnion } from '../../../main/store/actions';

type FormField =
    | {
          name: 'date';
          value: string;
      }
    | {
          name: 'time';
          value: string;
      }
    | {
          name: 'note';
          value: string;
      }
    | {
          name: 'value';
          value: number;
      }
    | {
          name: 'paid';
          value: boolean;
      };

export const cleaningFormActions = {
    setField: (field: FormField) =>
        createAction('cleaningForm/setField', field),
};

export type CleaningFormAction = ActionsUnion<typeof cleaningFormActions>;
