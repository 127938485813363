import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronRight);

const ForwardIcon = (props: Partial<Props>) => (
    <FontAwesomeIcon icon="chevron-right" size="1x" {...props} />
);

export default ForwardIcon;
