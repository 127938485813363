import { DateTime } from 'luxon';
import { useNavigate } from '@tanstack/react-location';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import {
    Box,
    Button,
    Card,
    CardProps,
    Link,
    Skeleton,
    styled,
} from '@mui/material';
import { HomeQueryResultBooking, homeQuery } from '../queries';
import formatMoney from '../../core/formatMoney';
import CheckinChecklist from '../../shared-ui/component/CheckinChecklist';
import CheckoutChecklist from '../../shared-ui/component/CheckoutChecklist';

const dayDiff = (date: string) =>
    Math.ceil(
        DateTime.fromFormat(date, 'yyyy-MM-dd').diffNow('days').toObject()
            .days || 0
    );

const formatRelative = (date: string) => {
    const diff = dayDiff(date);
    if (!diff) {
        return 'today';
    }

    if (diff < 0) {
        return `${-diff} days ago`;
    }

    return `${diff} days`;
};

const formatTimeDiff = (booking: {
    checkin: string;
    checkout: string;
    checkinTime: string;
    checkoutTime: string;
}) => {
    const checkoutDiff = dayDiff(booking.checkout);
    const checkinDiff = dayDiff(booking.checkin);

    if (checkoutDiff === 1) {
        return `checkout tomorrow ${booking.checkoutTime}`;
    }

    if (checkoutDiff < 0) {
        return `${formatRelative(booking.checkout)}`;
    }

    if (checkinDiff < 0) {
        return `Checkout in ${formatRelative(booking.checkout)} at ${
            booking.checkoutTime
        }`;
    }

    if (checkinDiff === 1) {
        return `tomorrow at ${booking.checkinTime}`;
    }

    return checkinDiff < 10
        ? `${formatRelative(booking.checkin)} at ${booking.checkinTime}`
        : formatRelative(booking.checkin);
};

interface Props {
    booking: HomeQueryResultBooking;
}

const BookingCardRoot = styled(Card, {
    shouldForwardProp: propName =>
        propName !== 'active' && propName !== 'urgent' && propName !== 'todo',
})<
    CardProps & {
        urgent?: boolean;
        active?: boolean;
        todo?: boolean;
    }
>(({ urgent, active, todo, theme }) => {
    let backgroundColor: string | undefined;

    if (active) {
        backgroundColor = `color-mix(in srgb, ${theme.palette.info.main} 15%, ${theme.palette.background.default})`;
    }

    if (todo) {
        backgroundColor = `color-mix(in srgb, ${theme.palette.warning.main} 15%, ${theme.palette.background.default})`;
    }

    if (urgent) {
        backgroundColor = `color-mix(in srgb, ${theme.palette.error.main} 15%, ${theme.palette.background.default})`;
    }

    return {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        marginBottom: '1rem',
        padding: '1rem',
        backgroundColor,
    };
});

const BookingCard = ({ booking }: Props) => {
    const navigate = useNavigate();

    const client = useApolloClient();

    const [requestUpdate, { loading: requestUpdateLoading }] = useMutation(
        gql`
            mutation requestBookingUpdateFromUpstream($bookingId: Int!) {
                requestBookingUpdateFromUpstream(bookingId: $bookingId)
            }
        `,
        {
            variables: {
                bookingId: booking.id,
            },
        }
    );

    const [updateBookingStatus, { loading: updateLoading }] = useMutation(
        gql`
            mutation updateBookingStatus($bookingId: Int!, $status: String!) {
                updateBookingStatus(bookingId: $bookingId, status: $status)
            }
        `,
        {
            variables: {
                bookingId: booking.id,
                status: 'complete',
            },
            refetchQueries: [
                {
                    query: homeQuery,
                },
            ],
        }
    );

    const checkinDiff = dayDiff(booking.checkin);
    const checkoutDiff = dayDiff(booking.checkout);

    if (requestUpdateLoading || updateLoading) {
        return <Skeleton height={125} />;
    }

    return (
        <BookingCardRoot
            urgent={
                (checkinDiff < 11 && !booking.detailsRequested) ||
                (checkinDiff < 3 && !booking.hasInstructions) ||
                (checkoutDiff < -5 && !booking.invoiceId)
            }
            active={
                (checkinDiff < 0 && checkoutDiff > 0) ||
                (checkinDiff < 7 && !booking.detailsRequested)
            }
            todo={checkoutDiff < -1 && !booking.invoiceId}
        >
            <Box>
                <Box>
                    <Link
                        color="primary"
                        variant="h5"
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate({
                                to: `/booking/${booking.id}`,
                            });
                        }}
                    >
                        {booking.countryCode && (
                            <img
                                src={`flags/${booking.countryCode.toLowerCase()}.png`}
                                alt={booking.countryCode}
                            />
                        )}{' '}
                        {booking.guestName}
                    </Link>
                </Box>
                <Box>
                    {`${
                        booking.adultCount +
                        booking.childCount +
                        booking.infantCount
                    } guests`}{' '}
                    {
                        DateTime.fromFormat(
                            booking.checkout,
                            'yyyy-MM-dd'
                        ).diff(
                            DateTime.fromFormat(booking.checkin, 'yyyy-MM-dd'),
                            'days'
                        ).days
                    }
                    {' nights'}
                </Box>
                <Box>{formatTimeDiff(booking)}</Box>
            </Box>
            <Box>
                {DateTime.fromSQL(
                    `${booking.checkin} ${booking.checkinTime}`
                ).toFormat('cccc HH:mm, LLL dd')}
                <Box>
                    {checkinDiff > 0 && checkinDiff < 10 && (
                        <div>
                            {(booking.guests.reduce(
                                (a, b) => a + (b.valid ? 1 : 0),
                                0
                            ) /
                                booking.guests.length) *
                                100}
                            {'% '}
                            guest data sent
                        </div>
                    )}
                    {(booking.actionRequired ||
                        booking.urgentActionRequired) && (
                        <>
                            <CheckinChecklist bookingId={booking.id} />
                            <CheckoutChecklist bookingId={booking.id} />
                        </>
                    )}
                    {booking.bookingShouldBeComplete && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                updateBookingStatus()
                                    .then(async () => {
                                        await client.clearStore();
                                        await client.reFetchObservableQueries();
                                    })
                                    .catch(error => {
                                        throw error;
                                    });
                            }}
                        >
                            Mark as complete
                        </Button>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    color: 'var(--ui-value-positive-color)',
                    textAlign: 'right',
                }}
            >
                {formatMoney(booking.revenue, booking.currency)}
                {!booking.revenue && (
                    <>
                        {' '}
                        <Button
                            variant="contained"
                            onClick={() => {
                                requestUpdate()
                                    .then(async () => {
                                        await client.clearStore();
                                        await client.reFetchObservableQueries();
                                    })
                                    .catch(addError => {
                                        throw addError;
                                    });
                            }}
                        >
                            Update
                        </Button>
                    </>
                )}
            </Box>
        </BookingCardRoot>
    );
};

export default BookingCard;
