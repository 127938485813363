import { ReactNode } from 'react';
import cn from 'clsx';
import classes from './HBox.module.css';

const HBox = ({
    children,
    className,
    expanded,
    wrap,
}: {
    children: ReactNode;
    className?: string;
    expanded?: boolean;
    wrap?: boolean;
}) => (
    <div
        className={cn(classes.HBox, className, {
            [classes.expanded]: expanded,
            [classes.wrap]: wrap,
        })}
    >
        {children}
    </div>
);

export default HBox;
