import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

library.add(faExclamationTriangle);

const ProblemIcon = (props: Partial<Props>) => (
    <FontAwesomeIcon
        icon="exclamation-triangle"
        color="red"
        size="1x"
        {...props}
    />
);

export default ProblemIcon;
