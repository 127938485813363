import classnames from 'clsx';
import UploadIcon from '../icon/UploadIcon';

import './DropBox.scss';

const DropBox = ({ highlight }: { highlight?: boolean }) => (
    <div className={classnames('DropBox', { highlight })}>
        <UploadIcon />
    </div>
);

export default DropBox;
