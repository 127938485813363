import { memo } from 'react';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import useUpdateBookingField from '../hooks/useUpdateBookingField';

const BookingBooleanField = memo(
    ({
        id,
        value,
        label,
        field,
    }: {
        label: string;
        field: string;
        id: number;
        value: boolean;
    }) => {
        const { currentValue, setValue, update } = useUpdateBookingField({
            id,
            field,
            initialValue: value,
            defaultValue: false,
        });

        return (
            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={currentValue}
                            onChange={event => {
                                setValue(event.target.checked);
                            }}
                        />
                    }
                    label={label}
                />

                {(value || '') !== currentValue && (
                    <>
                        {' '}
                        <Button onClick={update}>Save</Button>
                    </>
                )}
            </Box>
        );
    }
);

export default BookingBooleanField;
