import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionType = (...args: any[]) => any;
interface ActionCreatorMapObject {
    [actionCreator: string]: FunctionType;
}

// type UseActionsObject = (actions: ActionCreatorMapObject, deps: any[]) => ActionCreatorMapObject;
// type UseActionsArray = (actions: FunctionType[], deps: any[]) => FunctionType[]

// type UseActions = UseActionsObject | UseActionsArray;

const useActions = <T extends ActionCreatorMapObject>(actions: T): T => {
    const dispatch = useDispatch();
    return useMemo(
        () => bindActionCreators(actions, dispatch),
        [actions, dispatch]
    );
};

export default useActions;
