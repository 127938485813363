/* eslint @typescript-eslint/ban-types: 0 */
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearch, MakeGenerics } from '@tanstack/react-location';
import Button from '@istvan.xyz/ui/Button';
import Modal from '@istvan.xyz/ui/Modal';
import { useCallback, useState } from 'react';
import {
    messageTemplatesQuery,
    MessageTemplatesQueryResult,
    removeMessageTemplateQuery,
    messageTemplateQuery,
    saveMessageTemplateQuery,
} from '../../../booking/queries/messageTemplate';
import AbstractButton from '../../../base-ui/components/button/AbstractButton';
import assert from '../../../core/util/assert';
import ErrorBox from '../../../core/components/ErrorBox';
import SpinnerIcon from '../icon/SpinnerIcon';

type LocationGenerics = MakeGenerics<{
    Search: {
        name?: string;
    };
}>;

const MessageTemplateEditor = ({
    name,
    value: initialValue,
}: {
    name: string;
    value: string;
}) => {
    const navigate = useNavigate();

    const onFocusLost = useCallback(() => {
        navigate({ to: '/templates' });
    }, [navigate]);

    const [value, setValue] = useState(initialValue);

    const [saveMessageTemplate] = useMutation(saveMessageTemplateQuery);

    return (
        <Modal
            footer={
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button secondary onPress={onFocusLost}>
                        Close
                    </Button>
                    <Button
                        primary
                        disabled={initialValue === value}
                        onPress={() => {
                            saveMessageTemplate({
                                variables: {
                                    name,
                                    message: value,
                                },
                            })
                                .catch(error => {
                                    throw error;
                                })
                                .finally(() => {
                                    onFocusLost();
                                });
                        }}
                    >
                        Save
                    </Button>
                </div>
            }
        >
            <textarea
                value={value}
                onChange={event => {
                    setValue(event.target.value);
                }}
                style={{ width: 600, height: 400 }}
            />
        </Modal>
    );
};

const MessageTemplateView = ({ name }: { name: string }) => {
    const {
        loading,
        error,
        data: { messageTemplate: { value } } = { messageTemplate: {} },
    } = useQuery<{
        messageTemplate: {
            value: string;
        };
    }>(messageTemplateQuery, {
        variables: {
            name,
        },
    });

    if (error) {
        return <ErrorBox error={error} />;
    }

    if (loading) {
        return <SpinnerIcon />;
    }

    return <MessageTemplateEditor name={name} value={value || ''} />;
};

const MessageTemplateList = () => {
    const { name: currentName } = useSearch<LocationGenerics>();

    const navigate = useNavigate();

    const [removeMessageTemplate] = useMutation<{}, { name: string }>(
        removeMessageTemplateQuery,
        {
            refetchQueries: [{ query: messageTemplatesQuery }],
        }
    );
    // const { setField } = useActions(messageTemplateFormActions);
    const { loading, error, data } = useQuery<MessageTemplatesQueryResult>(
        messageTemplatesQuery
    );
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    assert(data);
    const { messageTemplates } = data;

    return (
        <>
            {currentName && <MessageTemplateView name={currentName} />}
            <button
                type="button"
                onClick={() => {
                    // updateParams({ page: 'messageTemplateForm' });
                }}
            >
                Add
            </button>
            <table>
                <tbody>
                    {messageTemplates.map(({ name }) => (
                        <tr key={name}>
                            <td>
                                <AbstractButton
                                    onPress={() => {
                                        navigate({
                                            to: '/templates',
                                            search: { name: name },
                                        });
                                    }}
                                >
                                    {name}
                                </AbstractButton>
                            </td>
                            <td>
                                <Button
                                    danger
                                    onPress={() => {
                                        removeMessageTemplate({
                                            variables: { name },
                                        }).catch(e => {
                                            throw e;
                                        });
                                    }}
                                >
                                    X
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default MessageTemplateList;
