import { gql } from '@apollo/client';

export interface HomeQueryResultBooking {
    id: number;
    reference: string;
    status: string;
    invoiceId?: string;
    countryCode: string;
    guestName: string;
    adultCount: number;
    childCount: number;
    infantCount: number;
    revenue: number;
    currency: 'EUR' | 'HUF' | 'GBP';
    actionRequired: boolean;
    urgentActionRequired: boolean;
    bookingShouldBeComplete: boolean;
    detailsRequested: boolean;
    detailsRecorded: boolean;
    hasInstructions: boolean;
    checkin: string;
    checkout: string;
    checkinTime: string;
    checkoutTime: string;
    lockboxCode?: string;
    guests: {
        id: number;
        valid: boolean;
    }[];
}

export interface HomeQueryResult {
    home: {
        bookings: HomeQueryResultBooking[];
    };
    cleaningList: {
        id: string;
        time: string;
        note: string;
        value: number;
        paid: boolean;
    }[];
}

export const homeQuery = gql`
    query Home {
        home {
            bookings {
                id
                reference
                status
                invoiceId
                countryCode
                guestName
                adultCount
                childCount
                infantCount
                revenue
                currency
                actionRequired
                urgentActionRequired
                detailsRequested
                detailsRecorded
                hasInstructions
                bookingShouldBeComplete
                checkin
                checkout
                checkinTime
                checkoutTime
                lockboxCode
                guests {
                    id
                    valid
                }
            }
        }
        cleaningList {
            id
            time
            note
            value
            paid
        }
    }
`;

export const statsQuery = gql`
    query countryStats {
        countryStats {
            countryCode
            countryName
            count
            revenue
            revenuePaid
        }
    }
`;

interface CountryStatsRow {
    countryCode: string;
    countryName: string;
    count: number;
    revenue: number;
    revenuePaid: number;
}

type CountryStats = CountryStatsRow[];

export interface StatsQueryResult {
    countryStats: CountryStats;
}

export const checklistQuery = gql`
    query Booking($id: Int!) {
        booking(id: $id) {
            id
            countryCode
            reference
            importedToGovernmentPortal
            detailsRequested
            detailsRecorded
            hasInstructions
            accommodationReady
            accommodationVerified
            keysSecured
            hasReview
            countryCode
            lockboxCode
            invoiceId
            guests {
                firstName
            }
            contactPhone
        }
    }
`;

export const alterBookingStateQuery = gql`
    mutation AlterBookingState($id: Int!, $fields: BookingStateInput!) {
        alterBookingState(id: $id, fields: $fields) {
            reference
            detailsRequested
            detailsRecorded
            hasInstructions
            accommodationReady
        }
    }
`;
