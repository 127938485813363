import { ReactNode, MouseEvent, FocusEvent, KeyboardEvent } from 'react';
import cn from 'clsx';
import './VBox.scss';

export const ExpandedRow = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => <div className={cn('expandedRow', className)}>{children}</div>;

interface Props {
    children: ReactNode;
    className?: string;
    expanded?: boolean;
    onClick?(event: MouseEvent): void;
    onBlur?(event: FocusEvent): void;
    onKeyPress?(event: KeyboardEvent): void;
}

const VBox = ({
    children,
    className,
    expanded,
    onClick,
    onBlur,
    onKeyPress,
}: Props) => (
    <div
        className={cn('vBox', className, { expanded })}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
    >
        {children}
    </div>
);

export default VBox;
