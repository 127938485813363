/* eslint max-lines: ["error", 270] */
/* eslint @typescript-eslint/ban-types: 0 */
import { useState } from 'react';
import Modal from '@istvan.xyz/ui/Modal';
import ButtonRow from '@istvan.xyz/ui/ButtonRow';
import Button from '@istvan.xyz/ui/Button';
import { useMutation } from '@apollo/client';
import {
    cleaningListQuery,
    createCleaningQuery,
    updateCleaningQuery,
    useRemoveCleaningMutation,
} from '../queries/cleaningQueries';
import FormRow from '../../main/component/common/form/FormRow';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';

const CleaningForm = ({
    cleaning,
    onClose,
    suggestedTime,
}: {
    onClose: () => void;
    suggestedTime?: string;
    cleaning?: {
        id: string;
        time: string;
        note: string;
        value: number;
        paid: boolean;
    };
}) => {
    const [createCleaning] = useMutation(createCleaningQuery, {
        refetchQueries: [
            {
                query: cleaningListQuery,
            },
        ],
    });
    const [updateCleaning] = useMutation(updateCleaningQuery, {
        refetchQueries: [
            {
                query: cleaningListQuery,
            },
        ],
    });
    const [removeCleaning] = useRemoveCleaningMutation();
    const [time, setTime] = useState(cleaning?.time || suggestedTime || '');
    const [note, setNote] = useState(cleaning?.note ?? '');
    const [value, setValue] = useState(cleaning?.value ?? '110');
    const [paid, setPaid] = useState(cleaning?.paid ?? false);
    const [loading, setLoading] = useState(false);

    if (loading) {
        return <SpinnerIcon />;
    }

    return (
        <Modal
            footer={
                <ButtonRow>
                    <Button
                        secondary
                        onPress={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    {cleaning && (
                        <Button
                            danger
                            onPress={() => {
                                setLoading(true);
                                removeCleaning({
                                    variables: { id: cleaning.id },
                                })
                                    .catch(error => {
                                        throw error;
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                                onClose();
                            }}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        primary
                        onPress={() => {
                            setLoading(true);
                            if (cleaning) {
                                updateCleaning({
                                    variables: {
                                        id: cleaning.id,
                                        time,
                                        note,
                                        value: +value,
                                        paid,
                                    },
                                })
                                    .catch(error => {
                                        throw error;
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            } else {
                                createCleaning({
                                    variables: {
                                        time,
                                        note,
                                        value: +value,
                                        paid,
                                    },
                                })
                                    .catch(error => {
                                        throw error;
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }
                            onClose();
                        }}
                    >
                        Save
                    </Button>
                </ButtonRow>
            }
        >
            <FormRow>
                <label>
                    <div className="FormLabel">Time</div>
                    <input
                        type="text"
                        value={time}
                        onChange={event => {
                            setTime(event.target.value);
                        }}
                    />
                </label>
            </FormRow>
            <FormRow>
                <label>
                    <div className="FormLabel">Note</div>
                    <input
                        type="text"
                        value={note}
                        onChange={event => {
                            setNote(event.target.value);
                        }}
                    />
                </label>
            </FormRow>
            <FormRow>
                <label>
                    <div className="FormLabel">Value</div>
                    <input
                        type="text"
                        value={value}
                        onChange={event => {
                            setValue(event.target.value);
                        }}
                    />
                </label>
            </FormRow>
            <FormRow>
                <label>
                    <div className="FormLabel">Paid</div>
                    <input
                        type="checkbox"
                        checked={paid}
                        value="1"
                        onChange={() => {
                            setPaid(!paid);
                        }}
                    />
                </label>
            </FormRow>
        </Modal>
    );
};

export default CleaningForm;
