import React, { ReactNode } from 'react';

import './TransparentButton.scss';
import Button from './AbstractButton';

interface Props {
    children: ReactNode;
    onPress?(): void;
}

const TransparentButton = ({ children, onPress }: Props) => (
    <Button className="TransparentButton" onPress={onPress}>
        {children}
    </Button>
);

export default TransparentButton;
