import React, { ReactNode } from 'react';

// import './FormRow.scss';

interface Props {
    children: ReactNode;
}

const FormRow = ({ children }: Props) => (
    <div className="FormRow FormGroup">{children}</div>
);

export default FormRow;
