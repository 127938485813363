import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSprayCanSparkles } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useState } from 'react';
import formatMoney from '../../core/formatMoney';
import assert from '../../core/util/assert';
import CleaningForm from './CleaningForm';
import './CleaningCard.css';

const CleaningCard = ({
    cleaning,
    suggestedTime,
}: {
    suggestedTime?: string;
    cleaning?: {
        id: string;
        time: string;
        note: string;
        value: number;
        paid: boolean;
    };
}) => {
    const [formOpen, setFormOpen] = useState(false);
    const cleaningTimeString = cleaning?.time || suggestedTime;
    assert(cleaningTimeString);
    const cleaningTime = DateTime.fromSQL(cleaningTimeString);
    return (
        <div
            className={clsx('CleaningCard', {
                CleaningCardAlert:
                    !cleaning ||
                    (cleaningTime < DateTime.local() && !cleaning?.paid),
            })}
        >
            <div>
                <FontAwesomeIcon icon={faSprayCanSparkles} />
            </div>
            <div>
                <a
                    onClick={() => {
                        setFormOpen(true);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    {cleaningTime.toFormat('cccc HH:mm, LLL dd')}
                </a>
                {formOpen && (
                    <CleaningForm
                        suggestedTime={suggestedTime}
                        cleaning={cleaning}
                        onClose={() => {
                            setFormOpen(false);
                        }}
                    />
                )}
            </div>
            {cleaning && (
                <div
                    style={{
                        color: 'var(--ui-value-negative-color)',
                        textAlign: 'right',
                    }}
                >
                    {formatMoney(-cleaning.value, 'EUR')}
                </div>
            )}
        </div>
    );
};

export default CleaningCard;
