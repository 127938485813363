import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

const alterBookingStateMutation = gql`
    mutation alterBookingState($id: Int!, $fields: BookingStateInput!) {
        alterBookingState(id: $id, fields: $fields) {
            reference
        }
    }
`;

const useUpdateBookingField = <T>({
    id,
    field,
    initialValue,
    defaultValue,
    isNumeric,
}: {
    id: string | number;
    field: string;
    initialValue: T;
    defaultValue: T;
    isNumeric?: boolean;
}) => {
    const client = useApolloClient();
    const [currentValue, setValue] = useState<T>(initialValue || defaultValue);
    const [alterBookingState] = useMutation(alterBookingStateMutation);

    const update = useCallback(() => {
        alterBookingState({
            variables: {
                id,
                fields: {
                    [field]: isNumeric ? +currentValue : currentValue,
                },
            },
        })
            .then(async () => {
                await client.clearStore();
                await client.reFetchObservableQueries();
            })
            .catch(error => {
                throw error;
            });
    }, [currentValue, id, client, alterBookingState, field]);

    return {
        currentValue,
        setValue,
        update,
    };
};

export default useUpdateBookingField;
