import { gql } from '@apollo/client';

export const calendarQuery = gql`
    query Bookings($statuses: [String]) {
        bookings(statuses: $statuses) {
            id
            reference
            status
            actionRequired
            urgentActionRequired
            countryCode
            guestName
            adultCount
            childCount
            infantCount
            guests {
                id
            }
            checkin
            checkout
        }
        cleaningList {
            id
            time
            note
            value
        }
    }
`;
