import { memo } from 'react';
import { Box, Button, TextField } from '@mui/material';
import useUpdateGuestField from '../hooks/useUpdateGuestField';

const GuestTextField = memo(
    ({
        id,
        value,
        label,
        field,
        type = 'text',
    }: {
        label: string;
        field: string;
        id: number;
        value?: string;
        type?: 'text' | 'numeric';
    }) => {
        const { currentValue, setValue, update } = useUpdateGuestField({
            id,
            field,
            initialValue: value,
            defaultValue: '',
            isNumeric: type === 'numeric',
        });

        return (
            <Box>
                <TextField
                    label={label}
                    value={currentValue}
                    onChange={event => {
                        setValue(event.target.value);
                    }}
                />
                {(value || '') !== currentValue && (
                    <>
                        {' '}
                        <Button variant="contained" onClick={update}>
                            Save
                        </Button>
                    </>
                )}
            </Box>
        );
    }
);

export default GuestTextField;
