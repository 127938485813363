import { gql } from '@apollo/client';
import useQuery from '../../core/hooks/useQuery';

const useBookingsQuery = gql`
    query bookingsQuery($invoiceMonth: String, $excludeStatuses: [String!]) {
        bookings(
            invoiceMonth: $invoiceMonth
            excludeStatuses: $excludeStatuses
        ) {
            id
            checkin
            checkout
            guestName
            invoiceId
            invoice {
                invoiceId
                currency
                grossPrice
                currencyRate
            }
            revenue
            currency
            status
        }
    }
`;

interface UseBookingVariables {
    invoiceMonth?: string;
    excludeStatuses?: string[];
}

interface UseBookingsData {
    bookings: {
        __typename: 'Booking';
        id: number;
        checkin: string;
        checkout: string;
        guestName: string;
        invoiceId: string;
        revenue: number;
        currency: 'EUR' | 'HUF' | 'GBP';
        invoice?: {
            invoiceId: string;
            currency: 'EUR' | 'HUF' | 'GBP';
            grossPrice: number;
            currencyRate: number;
        };
        status: 'complete' | 'cancelled';
    }[];
}

const useBookings = <D extends UseBookingsData>(
    variables: UseBookingVariables
) => {
    return useQuery<D, UseBookingVariables>(useBookingsQuery, {
        variables,
    });
};

export default useBookings;
