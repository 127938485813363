/* eslint-disable @typescript-eslint/no-non-null-assertion */
// cSpell:words highres
import { useQuery } from '@apollo/client';
import { statsQuery, StatsQueryResult } from '../queries';
import assert from '../../core/util/assert';

const Stats = () => {
    const { loading, error, data } = useQuery<StatsQueryResult>(statsQuery);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    assert(data);
    return <>Unavailable</>;
};

export default Stats;
