import { useQuery } from '@apollo/client';
import {
    FormControlLabel,
    Checkbox,
    FormGroup,
    Box,
    Typography,
} from '@mui/material';
import { useState, memo } from 'react';
import { Booking } from '../../domain/booking';
import BookingChecklistItemState from '../../booking/components/BookingChecklistItemState';
import { checklistQuery } from '../../main/queries';
import assert from '../../core/util/assert';
import BookingMessageGenerator from '../../booking/components/BookingMessageGenerator';
import GuestBookView from '../../booking/components/operation/GuestBookView';
import LockboxCodeForm from '../../booking/components/LockboxCodeForm';
import CountryForm from '../../booking/components/CountryForm';

interface Props {
    bookingId: number;
}

const CheckinChecklist = memo(({ bookingId }: Props) => {
    const { loading, error, data } = useQuery<{ booking: Booking }>(
        checklistQuery,
        {
            variables: { id: bookingId },
        }
    );
    const [governmentImportDialogOpen, setGovernmentImportDialogOpen] =
        useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [lockboxDialogOpen, setLockboxDialogOpen] = useState(false);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    assert(data);
    const { booking } = data;

    if (
        booking.importedToGovernmentPortal &&
        booking.detailsRequested &&
        booking.detailsRecorded &&
        booking.hasInstructions &&
        booking.accommodationReady
    ) {
        return null;
    }

    return (
        <Box>
            <Typography variant="h5" component="h2">
                Checkin checklist
            </Typography>
            <FormGroup>
                {!booking.countryCode && (
                    <CountryForm
                        contactPhone={booking.contactPhone}
                        bookingId={booking.id}
                        value={booking.countryCode}
                    />
                )}
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!booking.lockboxCode}
                                color="success"
                            />
                        }
                        label={'Lockbox code'}
                        onClick={() => {
                            if (!booking.lockboxCode) {
                                setLockboxDialogOpen(true);
                            }
                        }}
                    />
                    {lockboxDialogOpen && (
                        <LockboxCodeForm
                            bookingId={booking.id}
                            onClose={() => {
                                setLockboxDialogOpen(false);
                            }}
                        />
                    )}
                </Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={booking.importedToGovernmentPortal}
                            color="success"
                            onClick={() => {
                                if (!booking.importedToGovernmentPortal) {
                                    setGovernmentImportDialogOpen(true);
                                }
                            }}
                        />
                    }
                    label={'Imported booking to government portal'}
                />
                {governmentImportDialogOpen && (
                    <GuestBookView
                        bookingId={booking.id}
                        onClose={() => {
                            setGovernmentImportDialogOpen(false);
                        }}
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={booking.detailsRequested}
                            color="success"
                        />
                    }
                    label={'Guest details requested'}
                    onClick={() => {
                        if (!booking.detailsRequested) {
                            setMessageDialogOpen(true);
                        }
                    }}
                />
                {messageDialogOpen && (
                    <BookingMessageGenerator
                        bookingId={bookingId}
                        onClose={() => {
                            setMessageDialogOpen(false);
                        }}
                    />
                )}
                <BookingChecklistItemState
                    label={'Guest details recorded'}
                    booking={booking}
                    statusField="detailsRecorded"
                />
                <BookingChecklistItemState
                    label={'Instructions sent'}
                    booking={booking}
                    statusField="hasInstructions"
                />
                <BookingChecklistItemState
                    label={'Accommodation ready'}
                    booking={booking}
                    statusField="accommodationReady"
                />
            </FormGroup>
        </Box>
    );
});

export default CheckinChecklist;
