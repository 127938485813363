import { memo, useState } from 'react';
import Modal from '@istvan.xyz/ui/Modal';
import { gql, useMutation } from '@apollo/client';
import ButtonRow from '@istvan.xyz/ui/ButtonRow';
import Button from '@istvan.xyz/ui/Button';

const createBookingMutation = gql`
    mutation createBooking(
        $reference: String!
        $guestName: String!
        $contactPhone: String
        $earnings: String
        $checkinDate: String!
        $checkoutDate: String!
    ) {
        createBooking(
            reference: $reference
            guestName: $guestName
            contactPhone: $contactPhone
            earnings: $earnings
            checkinDate: $checkinDate
            checkoutDate: $checkoutDate
        )
    }
`;

const BookingCreateForm = memo(() => {
    const [createBooking] = useMutation(createBookingMutation);
    const [reference, setReference] = useState('');
    const [guestName, setGuestName] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [earnings, setEarnings] = useState('');
    const [checkinDate, setCheckinDate] = useState('');
    const [checkoutDate, setCheckoutDate] = useState('');
    return (
        <Modal
            footer={
                <ButtonRow>
                    <Button
                        primary
                        onPress={() => {
                            createBooking({
                                variables: {
                                    reference,
                                    guestName,
                                    contactPhone,
                                    earnings,
                                    checkinDate,
                                    checkoutDate,
                                },
                            })
                                .then(() => {
                                    setReference('');
                                })
                                .catch(error => {
                                    throw error;
                                });
                        }}
                    >
                        Save
                    </Button>
                </ButtonRow>
            }
        >
            <label>
                <div className="FormLabel">Reference</div>
                <input
                    type="text"
                    value={reference}
                    onChange={event => {
                        setReference(event.target.value);
                    }}
                />
            </label>
            <label>
                <div className="FormLabel">Guest name</div>
                <input
                    type="text"
                    value={guestName}
                    onChange={event => {
                        setGuestName(event.target.value);
                    }}
                />
            </label>
            <label>
                <div className="FormLabel">Contact phone</div>
                <input
                    type="text"
                    value={contactPhone}
                    onChange={event => {
                        setContactPhone(event.target.value);
                    }}
                />
            </label>
            <label>
                <div className="FormLabel">Earnings</div>
                <input
                    type="text"
                    value={earnings}
                    onChange={event => {
                        setEarnings(event.target.value);
                    }}
                />
            </label>
            <label>
                <div className="FormLabel">Checkin date</div>
                <input
                    type="text"
                    value={checkinDate}
                    onChange={event => {
                        setCheckinDate(event.target.value);
                    }}
                />
            </label>

            <label>
                <div className="FormLabel">Checkout date</div>
                <input
                    type="text"
                    value={checkoutDate}
                    onChange={event => {
                        setCheckoutDate(event.target.value);
                    }}
                />
            </label>
        </Modal>
    );
});

export default BookingCreateForm;
