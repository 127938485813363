import {
    DocumentNode,
    OperationVariables,
    QueryHookOptions,
    TypedDocumentNode,
    useQuery as useActualQuery,
} from '@apollo/client';
import assert from '../util/assert';

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Same as useQuery from @apollo/client with improved return types
 */
const useQuery = <
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
) => {
    const { loading, data, error, ...rest } = useActualQuery<TData, TVariables>(
        query,
        options
    );

    if (error) {
        return { loading, data, error, ...rest };
    }

    if (loading) {
        return { loading, data, error, ...rest };
    }

    assert(data);

    return { loading, data, error, ...rest };
};

export default useQuery;
