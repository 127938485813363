import { memo, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';
import { baseUrl } from '../../main/client';
import importCodeTemplate from '../automation/importCodeTemplate';
import copyText from '../../core/util/copyText';

interface Guest {
    id: number;
    firstName: string;
    lastName: string;
    sex: string;
    nationality: string;
    country: string;
    birthDate: string;
    birthPlace: string;
    postcode: string;
    address: string;
    idDocumentType?: string;
    idDocumentId?: string;
    valid: boolean;
    price: number;
    touristTax: number;
    nationalityNameHU: string;
    countryNameHU: string;
}

const GuestDocumentView = memo(
    ({
        open,
        setOpened,
        setClosed,
        showNext,
        showPrevious,
        bookingId,
        guest,
        guestIndex,
    }: {
        open: boolean;
        setOpened: () => void;
        setClosed: () => void;
        showNext: () => void;
        showPrevious: () => void;
        bookingId: number;
        guest: Guest;
        guestIndex: number;
    }) => {
        const [copiedAutomationCode, setCopiedAutomationCode] = useState(false);
        const [copiedPostcode, setCopiedPostcode] = useState(false);
        const [copiedBirthPlace, setCopiedBirthPlace] = useState(false);
        const [rotation, setRotation] = useState('0deg');

        const importCode = `
        ${importCodeTemplate}(${JSON.stringify(guest)}).catch(error => {
            throw error;
        });
    `;

        return (
            <>
                <div
                    onClick={setOpened}
                    style={{
                        backgroundImage: `url(${baseUrl}/api/guest/document/${bookingId}/${guestIndex}?key=${localStorage.getItem(
                            'token'
                        )})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                    }}
                />
                {open && (
                    <Dialog open={open} onClose={setClosed} fullScreen>
                        <DialogContent>
                            <div
                                style={{
                                    transform: `rotate(${rotation})`,
                                    height: '90vh',
                                    width: '90vw',
                                    backgroundImage: `url(${baseUrl}/api/guest/document/${bookingId}/${guestIndex}?key=${localStorage.getItem(
                                        'token'
                                    )})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Typography>
                                <Button
                                    onClick={() => {
                                        copyText(guest.birthPlace)
                                            .then(() => {
                                                setCopiedBirthPlace(true);
                                            })
                                            .catch(error => {
                                                throw error;
                                            });
                                    }}
                                >
                                    Birth place: {guest.birthPlace}
                                    {copiedBirthPlace ? ' Copied' : ''}
                                </Button>
                            </Typography>
                            <Typography>
                                <Button
                                    onClick={() => {
                                        copyText(guest.postcode)
                                            .then(() => {
                                                setCopiedPostcode(true);
                                            })
                                            .catch(error => {
                                                throw error;
                                            });
                                    }}
                                >
                                    Postcode: {guest.postcode}
                                    {copiedPostcode ? ' Copied' : ''}
                                </Button>
                            </Typography>
                            <Button
                                onClick={() => {
                                    copyText(importCode)
                                        .then(() => {
                                            setCopiedAutomationCode(true);
                                        })
                                        .catch(error => {
                                            throw error;
                                        });
                                }}
                            >
                                {copiedAutomationCode
                                    ? 'Copied'
                                    : 'Copy details automation code'}
                            </Button>
                            <Button
                                onClick={() => {
                                    window.open(
                                        `${baseUrl}/api/guest/document/${bookingId}/${guestIndex}?key=${localStorage.getItem(
                                            'token'
                                        )}`
                                    );
                                }}
                            >
                                Open document
                            </Button>{' '}
                            <Button
                                onClick={() => {
                                    setRotation('900deg');
                                }}
                            >
                                Rotate 90
                            </Button>
                            <Button
                                onClick={() => {
                                    setRotation('270deg');
                                }}
                            >
                                Rotate 270
                            </Button>
                            <Button onClick={showPrevious}>Previous</Button>
                            <Button onClick={showNext}>Next</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        );
    }
);

export default GuestDocumentView;
