import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { client } from './main/client';
import App from './main/component/App';

const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

createRoot(document.getElementById('app')!).render(
    <ApolloProvider client={client as any}>
        <ThemeProvider theme={defaultTheme}>
            <App />
        </ThemeProvider>
    </ApolloProvider>
);
