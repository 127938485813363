import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const CopyIcon = (props: Partial<FontAwesomeIconProps>) => (
    <FontAwesomeIcon icon={faCopy} size="1x" {...props} />
);

export default CopyIcon;
